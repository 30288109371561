import { getCompany } from "@/actions/auth";
import Module from "@/components/core/Module";
import { isTheRolEnabled } from "@/helpers/featureFlagHelpers";
import { useGetUserComissionsPeriod } from "@/hooks/user/useGetUserComissionsPeriod";
import { Routes } from "@/routes";
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import _ from "lodash";
import { ChevronRight } from 'lucide-react';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { ComissionInterface, ComissionType } from '../../../interfaces/Interface';
import ComissionItem from "./ComissionItem";
import { LoaderComissionModule } from "./LoaderComissionModule";
import { Separator } from "@/components/ui/separator";

interface ComissionModuleProps {
    groupSelected?: string,
    userSelected?: string
};

const isSameDate = (date1: Date, date2: Date) =>
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear();

export default function ComissionModule({ groupSelected, userSelected }: ComissionModuleProps) {

    const { comissions, totalBountyByCurrency, params, isLoading } = useGetUserComissionsPeriod({
        externalParams: {
            group: groupSelected,
            user: userSelected,
            limit: 65,
            page: 1
        }
    });

    const router = useRouter();

    const categories: ComissionType[] = _.get(getCompany(), 'comissions.types', []).filter(({ active }) => active);
    const categoriesToMap = useMemo(() => _.keyBy(categories, 'code'), [categories]);

    const useComissions: { date: string, comissions: ComissionInterface[] }[] = useMemo(() => {
        const result: { date: string, comissions: ComissionInterface[] }[] = [];
        let lastDate: null | Date = null;

        comissions.forEach((comission) => {
            const currentDate = new Date(comission.createdAt);

            if (!lastDate || !isSameDate(currentDate, lastDate)) {
                result.push({
                    date: format(currentDate, "PPP", { locale: es }).split(' ').slice(0, 3).join(' '),
                    comissions: []
                });

                lastDate = currentDate;
            };

            const resultLength = result.length;
            result[resultLength == 0 ? 0 : resultLength - 1].comissions.push(comission)
        });

        return result;
    }, [comissions]);

    const totalMonthValue = useMemo(() => {
        let value: { confirmed: { currency: string, value: number }[], pending: { currency: string, value: number }[] } = {
            confirmed: [],
            pending: []
        };

        value.confirmed = Object.entries(totalBountyByCurrency.confirmed).map(([currency, value]) => ({ currency, value }));
        value.pending = Object.entries(totalBountyByCurrency.pending).map(([currency, value]) => ({ currency, value }));
        return value;
    }, [totalBountyByCurrency])

    const isComercial = isTheRolEnabled(['comercial']);

    const handleViewAllComissions = () => {
        if (isComercial) return router.push(Routes.BACKOFFICE_COMISSION);
        return router.push(Routes.COMISSION_REPORT);
    };

    return (
        <Module
            title="Comisiones"
            cardStyle="lg:rounded-r-none min-h-[380px] h-full md:mx-0 mx-2 xl:rounded-r-none  2xl:rounded-r-none flex-grow flex flex-col"
            className="flex flex-col flex-grow px-0 sm:px-0 py-0 pb-0"
            action={
                <button
                    type="button"
                    onClick={handleViewAllComissions}
                    className="flex items-center text-primary hover:text-primary/60 duration-100 justify-center h-[34px]"
                >
                    <ChevronRight size={25} />
                </button>
            }
        >
            <div className="flex flex-col w-full flex-grow pb-1">
                <div className="flex-grow relative w-full">
                    <div className="absolute px-3 sm:px-5 w-full overflow-y-auto h-full left-0 top-0">
                        {
                            isLoading
                                ? <LoaderComissionModule />
                                : (!isLoading && comissions.length == 0)
                                    ? <EmptyComissions />
                                    : (
                                        <ul className="grid gap-4">
                                            {
                                                useComissions.map(({ comissions, date }, index) => {
                                                    return (
                                                        <li key={index} className="w-full grid gap-1">
                                                            <p className="mb-1 text-[16px] text-gray-700 font-medium">
                                                                {date}
                                                            </p>
                                                            <ul className="grid gap-2 px-1">
                                                                {
                                                                    comissions.map((comission) => (
                                                                        <li key={comission._id}>
                                                                            <ComissionItem
                                                                                categoriesToMap={categoriesToMap}
                                                                                comission={comission as any}
                                                                            />
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    )
                        }
                    </div>
                </div>
                {
                    !isLoading && (totalMonthValue.pending.length > 0 || totalMonthValue.confirmed.length > 0) && (
                        <div className="min-h-[55px] py-2 flex flex-col gap-2 border-t px-3 sm:px-5">
                            {
                                (totalMonthValue.pending.length > 0 && !isLoading) && (
                                    <TotalMonthValue
                                        title=" Por aprobar"
                                        values={totalMonthValue.pending}
                                    />
                                )
                            }
                            {totalMonthValue.confirmed.length > 0 && totalMonthValue.pending.length > 0 && (
                                <Separator />
                            )}
                            {
                                (totalMonthValue.confirmed.length > 0 && !isLoading) && (
                                    <TotalMonthValue
                                        title="Obtenido este mes"
                                        values={totalMonthValue.confirmed}
                                    />
                                )
                            }
                        </div>
                    )
                }
            </div>
        </Module>
    )
};

export function EmptyComissions() {
    return <div className="w-full p-8 flex flex-col rounded-lg items-center  justify-center">
        <h3 className="text-gray-500 text-base">Sin comisiones</h3>
    </div>
}

interface TotalMonthValueProps {
    title: string,
    values: { currency: string, value: number }[]
}

export function TotalMonthValue({ title, values }: TotalMonthValueProps) {
    return (
        <div className="justify-between flex">
            <p className="font-medium text-gray-800 text-[14px]">
                {title}
            </p>
            <div className="grid gap-1">
                {values.map(({ currency, value }) => (
                    <p className="text-gray-500 text-[13px]" key={currency}>
                        <b>{value}</b>{' '}
                        {currency}
                    </p>
                ))}
            </div>
        </div>
    )
}