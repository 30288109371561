import React, { useState, useEffect } from "react";
import { Avatar, Box, Card, CardActionArea, CardHeader, Grid, Theme, Icon, Typography, Button, CircularProgress, Chip } from "@mui/material";
// import "react-quill/dist/quill.snow.css";
import { useRouter } from "next/router";
import moment from 'moment'
import { BasicUser, InterfaceTypeComplete } from '../../interfaces/Interface'
import { SxProps } from "@mui/system";
import { Campaign, Inbox, TextSnippet } from "@mui/icons-material";
import Link from '../core/NextLinkWraped'
import Module from '../core/Module'
import InstanceCard from './InstanceCard'
import InstanceCardAdd from './InstanceCardAdd'
import { list, listAll, listUser } from '../../actions/instance'
import { setSelectedUser } from '../../redux/actions/main'
import { connect } from 'react-redux';
import { isAuth } from "../../actions/auth";
import { useQuery } from '@tanstack/react-query'


moment.locale();

interface InstanceListModule {
    sx?: SxProps<Theme>;
    id: string;
    groupFilter?: string,
    clickedAvisos?: () => void
}

function InstanceListModule({ sx, id, groupFilter = undefined, clickedAvisos }: InstanceListModule) {
    var router = useRouter()

    let getData = async () => {
        if (id == 'all') {
            if (isAuth().roles.indexOf('comercial') < 0 && isAuth().roles.indexOf('super') < 0) {
                router.push('/evaluation/list/' + isAuth()._id)
            } else {
                let response = await listAll(groupFilter)
                if (response.error) return response.error
                return { instances: response, useSellerName: true }
            }
        } else if (isAuth()._id == id) {

            let response = await list()
            if (response.error) return response.error
            return { instances: response, useSellerName: false }
        } else {
            let response = await listUser(id)
            if (response.error) return response.error
            return { instances: response, useSellerName: true }

        }
    }

    let queryInstances = useQuery({
        queryKey: ['instances', id, groupFilter],
        queryFn: getData
    })
    let loading = queryInstances.isLoading
    let useSellerName = queryInstances.data?.useSellerName ? queryInstances.data.useSellerName : false
    let instances = queryInstances.data?.instances ? queryInstances.data.instances : []

    return (
        <Module
            cardStyle="lg:rounded-r-none h-full md:mx-0 mx-2 xl:rounded-r-none 2xl:rounded-r-none"
            titleElement={<span onClick={clickedAvisos} >Avisos</span>}
            counter={(!instances || instances.filter((i) => !i.seenByUser).length == 0) ? false : instances.filter((i) => !i.seenByUser).length + ""}
            counterColor={instances && instances.filter((i) => !i.seenByUser).length > 0 ? 'error' : 'info'}
            action={isAuth() && (isAuth().roles.indexOf('comercial') >= 0 || isAuth().roles.indexOf('super') >= 0) ?
                <Grid item sx={{ width: '100%' }}><InstanceCardAdd user_id={id} /></Grid> : ''}
            sx={{ p: 0, height: '100%', ...sx }}>
            {/* <Chip size="small" color={instances && instances.filter((i) => !i.seenByUser).length > 0 ? 'error' : 'info'} label={!instances ? 0 : instances.filter((i) => !i.seenByUser).length}/> */}
            <Box sx={{ height: '306px', overflowY: 'auto', mb: 2 }}>
                <Grid container direction={'row'} justifyItems={'flex-start'} alignItems={'flex-start'} spacing={1}>
                    {instances && instances.map((i) => <Grid item sx={{ width: '100%' }}>
                        <InstanceCard instance={i} sx={{ pb: 1, borderRadius: 0, boxShadow: 'none', borderBottom: '1px #ecf3fa solid' }} showVendedor={useSellerName} />
                    </Grid>)}
                    {!loading && instances.length == 0 && (
                        <Grid item sx={{ width: '100%' }}>
                            <Typography sx={{ mt: '30px', textAlign: 'center', }}>
                                <Inbox sx={{ mr: '10px' }} />
                                {(isAuth() && isAuth().roles && (isAuth().roles.indexOf('comercial') >= 0 || isAuth().roles.indexOf('super') >= 0)) ?
                                    "Este vendedor no tiene avisos o instancias generadas" :
                                    "No tienes avisos recientes"
                                }
                            </Typography>
                        </Grid>
                    )}
                    {loading && (
                        <Grid container justifyContent='center' alignItems="center" sx={{ mt: '30px' }}>
                            <CircularProgress />
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Grid container direction={'column'} justifyContent={'top'} alignItems={'center'} sx={{ pb: 1, px: 1 }} >
                <Grid item><Link href={`/evaluation/list/${id}`}><Button variant="outlined">Ver más</Button></Link></Grid>
            </Grid>
        </Module>

    );
}

export default InstanceListModule
