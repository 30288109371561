import Module from '@/components/core/Module';
import { Button } from '@/components/ui/button';
import { BarChart } from '@mui/icons-material';
import React from 'react'
import VisibilitySensor from 'react-visibility-sensor';

export default function ShowChartsElement({
    onChangeShowCharts,
    toggleShowCharts
}) {
    return (
        <VisibilitySensor onChange={onChangeShowCharts}>
            <Module>
                <div className='flex items-center justify-center w-full flex-grow'>
                    <Button
                        onClick={toggleShowCharts}
                        variant={'outline-primary'}
                        className='flex items-center gap-2'
                    >
                        <BarChart />
                        Mostrar gráficas
                    </Button>
                </div>
            </Module>
        </VisibilitySensor>
    )
}
