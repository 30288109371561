import { getCookie } from "@/actions/auth";
import pusherJs from "pusher-js";


const pusherInstance = null

const initPusher = () => {
    return getCookie('token') ? pusherInstance ?? new pusherJs('977e80b67dc6e57afd8a', {
        cluster: 'mt1',
        authEndpoint: process.env.PUSHER_AUTH_ENDPOINT ?? `${process.env.API_NEXT}/pusher/auth`,
        userAuthentication: {
            endpoint: process.env.PUSHER_AUTH_ENDPOINT ?? `${process.env.API_NEXT}/pusher/auth`,
            transport: 'ajax',
            headersProvider: () => {
              return {
                'Authorization': 'JWT '+getCookie('token'),
              }
            }
        },
        channelAuthorization: {
            endpoint: process.env.PUSHER_AUTH_ENDPOINT ?? `${process.env.API_NEXT}/pusher/auth`,
            transport: 'ajax',
            headersProvider: () => {
              return {
                'Authorization': 'JWT '+getCookie('token'),
              }
            }
        }
    }) : null;
}

export default initPusher;