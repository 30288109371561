

import { defaultvaluesInfoGoals } from '@/components/StatusCard';
import { calculateGoal, getAllGoalsInformation, getAllGroups, getColorCall, updateGoalInformation } from "@/components/StatusCard/goalHelpers";
import { IGoalInfo, IGoalStateKey, STATES_GOAL } from '@/components/StatusCard/interface';
import useGetCalls from "@/hooks/requests/useGetCalls";
import { updateColorsUsersGroup } from '@/redux/actions/main';
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from "react-redux";
import useGetGoals from './requests/useGetGoals';
const fields = [['contacts', 'contactsValue'], ['tracing', 'tracingValue'], ['sale', 'saleValue']]

export default function useStatusCard({ user, groupFilter, usersGroup }) {
    const [isFirstLoading, setIsFirstLoading] = useState<boolean>(false)
    const [allSubGroups, setAllSubGroups] = useState<null | any[]>(null)
    const [allUsers, setAllUser] = useState<Array<any>>([])
    const [isLoadingBuildData, setIsLoadingBuildData] = useState(false)
    const [goalInformation, setGoalInformation] = useState<IGoalInfo>(defaultvaluesInfoGoals);
    const [keywordSearch, setKeywordSearch] = useState<string>('')
    const [dateGoal, setDateGoal] = useState({ end: '', start: '' })
    const [filter, setFilter] = useState<string>('')
    const [dataGoal, setDataGoal] = useState<any[]>([])
    const [selection, setSelection] = useState<'today' | 'yesterday'>('today')

    const dispatch = useDispatch();
    const generateColorsList = (listGoalsToChangeColor) => dispatch(updateColorsUsersGroup(listGoalsToChangeColor))

    const { result, isLoading } = useGetGoals({
        byUser: user,
        group: undefined,
        months: undefined,
        period: 'now',
        queryKey: ['status-of-goals', selection],
        yesterday: selection == 'yesterday'
    })

    const { calls, isLoadingFetchCall } = useGetCalls({ user })

    const addColorAndUserToCall = (addColorsGoal) => {
        generateColorsList(addColorsGoal)

        let callsColor = !Array.isArray(calls) ? [] : calls
        callsColor = callsColor.map(call => ({
            ...call,
            color: getColorCall(call.updatedAt),
        }));

        callsColor.forEach(call => {
            const user = call.user?.[0];
            if (user) {
                const matchingGoal = addColorsGoal.find(goal => goal.user._id === user._id);
                if (matchingGoal) {
                    matchingGoal.lastCall = call;
                }
            }
        });

        return callsColor
    }

    const generateGoalData = useCallback(() => {
        setIsLoadingBuildData(true)
        const groupsActivesFilter = groupFilter && usersGroup
            .filter(group => group.ancestors.includes(groupFilter) || group._id === groupFilter)

        const userActives = groupFilter && groupsActivesFilter.map(group => group.users)
            .flat()
            .filter(user => user.active)
            .map(user => user._id)

        const allGoals = [...result]
        const infoGoalToModify = { ...defaultvaluesInfoGoals }

        const addColorsGoal = allGoals.map((goal) => {
            const goalToModify = _.cloneDeep(goal);

            if (goalToModify.start) {
                const start = goal.start
                const end = goal.end

                if (start && end) setDateGoal({ end, start })
                updateGoalInformation(goalToModify, infoGoalToModify, selection)
            }

            if (goalToModify.goal) {
                const currentGoal = goalToModify.goal[0]
                const goalInfoArray: IGoalStateKey[] = [];

                for (let i = 0; i < fields.length; ++i) {
                    const [expected, value] = fields[i]

                    const goalInfo = calculateGoal({
                        value: currentGoal[value],
                        expected: currentGoal[expected],
                    }, _.cloneDeep(goalToModify))

                    goalInfoArray.push({ ...goalInfo, key: expected })
                }

                const statesGoal = goalInfoArray.map(goalInfo => goalInfo.state);
                const isActiveGoal = groupFilter && userActives.includes(goal.user._id);

                const updateGoalInfo = (incrementProperty, color) => {
                    if (groupFilter ? isActiveGoal : true) {
                        infoGoalToModify[incrementProperty]++;
                        goalToModify.color = color;
                    }
                };

                if (statesGoal.includes(STATES_GOAL.VERYBAD)) {
                    updateGoalInfo('veryLates', '#d32f2f');
                } else if (statesGoal.includes(STATES_GOAL.BAD)) {
                    updateGoalInfo('lates', '#ED6C02');
                } else if (statesGoal.includes(STATES_GOAL.UPTODATE)) {
                    updateGoalInfo('upToDate', '#2e7d32');
                }

                if (goalInfoArray.every(metaInfo => metaInfo.complete)) {
                    if (groupFilter ? isActiveGoal : true) {
                        infoGoalToModify.complete++;
                        goalToModify.color = '#2e7d32';
                    }
                }

                goalInfoArray.forEach(goal => currentGoal[`color${goal.key}`] = goal.color)
                goalInfoArray.forEach(goal => currentGoal[`expected${goal.key}`] = goal.expected)
                infoGoalToModify.color = infoGoalToModify.lates > 0 ? "#dc3545" : "#28a745";
            }
            return goalToModify
        })

        addColorAndUserToCall(addColorsGoal)

        const listUsersToSearch: any = []
        let allData = groupFilter ? groupsActivesFilter : usersGroup
        allData = !Array.isArray(allData) ? [] : allData
        allData = allData.map(group => getAllGoalsInformation(group, listUsersToSearch, addColorsGoal))

        setGoalInformation(infoGoalToModify)
        setAllUser(listUsersToSearch.flat())
        setIsLoadingBuildData(false)
        setIsFirstLoading(true)
        setAllSubGroups(allData.length > 0 ? getAllGroups(allData) : [])

        return setDataGoal(allData)
    }, [result, selection])

    useEffect(() => {
        if (result.length == 0 && dataGoal.length == 0) return;
        generateGoalData()
    }, [result])

    useEffect(() => {
        setIsFirstLoading(false)
    }, [user])

    const resultToSearchSeller: Array<any> = useMemo(() => {
        const usersFilter = allUsers.filter(user => _.get(user, 'active', true) && (user.name + ' ' + user.lastname)
            .toLocaleLowerCase()
            .includes(keywordSearch.toLocaleLowerCase()))

        if (filter !== '') return usersFilter.filter(user => user.goal.color === filter)
        return usersFilter
    }, [keywordSearch, filter])

    return {
        allSubGroups,
        resultToSearchSeller,
        goalInformation,
        dateGoal,
        setKeywordSearch,
        setFilter,
        setSelection,
        filter,
        keywordSearch,
        selection,
        isLoading: !isFirstLoading ? isLoading || isLoadingFetchCall || isLoadingBuildData : false
    }
}