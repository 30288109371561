import callModel from "./callModel";
import chatsModel from "./chatsModel";
import closesModel from "./closesModel";
import contactsModel from "./contactsModel";
import prospectModel from "./prospectModel";
import receiptContactModel from "./receiptContactModel";
import receiptModel from "./receiptModel";
import tracingModel from "./tracingModel";
import unofficialWhatsappModel from "./unofficialWhatsappModel";
import visitsModel from './visitsModel';
import whatsappNoteModel from "./whatsappNoteModel";
import tagChangeModel from "./tagChangeModel";

export default (module: string) => {
    switch (module) {
        case 'calls': return callModel
        case 'tracings': return tracingModel
        case 'contacts': return prospectModel
        case 'closes': return closesModel
        case 'closescontact': return closesModel
        case 'receipts': return receiptModel
        case 'receiptscontact':
        case 'receiptsventiacontact': return receiptContactModel
        case 'whatsapp-notes': return whatsappNoteModel
        case 'visits': return visitsModel
        case 'chats': return chatsModel
        case 'chats-wpp': return unofficialWhatsappModel
        case 'ventiacontacts': return contactsModel
        case 'closedContactsCount':
        case 'vistCountToContacts':
        case 'contactAdditionalData':
        case 'contactStatusTag':
        case 'contactsState': return contactsModel
        case 'tagChange': return tagChangeModel
        default: return receiptModel
    }
};