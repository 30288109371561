import { Routes } from '@/routes';
import { useRouter } from 'next/router';
import Module from '../../core/Module';
import { ReusableTable } from '../../Table/ReusableTable';
import { columnsMeetings } from './columns';
import useGetMeetingsList from './hooks/useGetMeetingsList';
import { isFeatureEnabled, Feature } from '../../../helpers/featureFlagHelpers';

export default function MeetingsReport({ userSelected, group }) {
    if (!isFeatureEnabled(Feature.ENABLE_MEETINGS)) return null;

    const router = useRouter()
    const { meetings, isPending } = useGetMeetingsList({ userSelected, group })

    const onHandleClickRow = (meeting: Record<string, any>) => {
        router.push(Routes.CONTACT.concat(`?id=${meeting.contact._id}`))
    }

    return (
        <Module title={'Agenda'} className="pt-0 px-0">
            <div className="h-[300px] flex flex-col relative w-full">
                <ReusableTable
                    classNameHead="[&>div]:border-primary [&>div]:text-white [&>div]:capitalize"
                    classNameHeader='bg-primary hover:bg-primary h-[45px]'
                    classNameCell='hover:cursor-pointer'
                    onClickRow={onHandleClickRow}
                    empyMessage="Sin reuniones"
                    activePagination={false}
                    isLoading={isPending}
                    pageSize={500}
                    withStripes
                    dataTable={{
                        columns: columnsMeetings,
                        data: meetings
                    }}
                />
            </div>
        </Module>
    )
}
