import _ from "lodash"

export const columnsTable: any[] = [
    {
        accessorKey: "seller",
        header: <div className="text-start">
            <h5 className="text-[14px]">Vendedor</h5>
        </div>,
    },
    {
        accessorKey: "contacts",
        header: <div className="w-full flex items-center"> 
            <h5 className="text-[14px]">Contactos</h5>
        </div>,
    },
    {
        accessorKey: "tracing",
        header: <div className="w-full flex items-center"> <h5 className="text-[14px]">Seguimientos</h5></div>,
    }, {
        accessorKey: "sales",
        header: <div className="w-full flex items-center"> <h5 className="text-[14px]">Ventas</h5></div>,
    }
]