import { isAuth } from "@/actions/auth"
import { meetingTypes } from "@/components/crm/Modal/meeting/select-meeting-type"
import { ItemAgentForColumns } from "@/components/item-agent-for-columns"
import { Button } from "@/components/ui/button"
import SimpleTooltip from "@/components/ui/simple-tooltip"
import { isTheRolEnabled } from "@/helpers/featureFlagHelpers"
import dayjs from "dayjs"
import _ from "lodash"
import { ChevronRight } from 'lucide-react'

export const columnsMeetings = [
    {
        accessorKey: "startMeeting",
        header: "Horario",
        cell: ({ row }) => {
            const isFirstMeeting = row.index == 0
            const startDate = _.get(row.original, 'content.schedule', '')
            const endDate = _.get(row.original, 'content.endMeeting', '')

            const currentDateToUnix = new Date().getTime()
            const isNow = currentDateToUnix >= new Date(startDate).getTime() && currentDateToUnix <= new Date(endDate).getTime()

            return <span className={`${isFirstMeeting ? 'text-primary' : ''}`}>
                {dayjs(startDate).format('HH:mm:ss')}
                {
                    isNow
                        ? ' (Ahora)'
                        : ''
                }
            </span>
        }
    },
    {
        accessorKey: "contact",
        header: "Contacto",
        cell: ({ row }) => {
            const { firstName, lastName, statusTag } = _.get(row.original, 'contact', {});
            const isFirstMeeting = row.index == 0

            return <span className={`${isFirstMeeting ? 'text-primary' : ''}`}>
                {firstName} {lastName}
            </span>
        }
    },
    {
        accessorKey: "medium",
        header: "Medio",
        cell: ({ row }) => {
            const medium = _.get(row.original, 'content.meetingType', '')
            const { icon, label } = meetingTypes[medium] || {}
            const isFirstMeeting = row.index == 0

            return <div className={`flex items-center gap-2 ${isFirstMeeting ? 'text-primary' : ''}`}>
                <span className={"[&>svg]:w-[18px] [&>svg]:h-[18px]"}>
                    {icon}
                </span>
                <span>{label}</span>
            </div>
        }
    },
    ...(isTheRolEnabled(['comercial', 'super']) ? [
        {
            accessorKey: "agent",
            header: "Vendedor",
            cell: ({ row }) => {
                const isFirstMeeting = row.index == 0
                const agent = row.getValue('agent')
                return <div className={`flex items-center gap-2 ${isFirstMeeting ? 'text-primary' : ''}`}>
                    {
                        agent._id === isAuth()._id
                            ? 'Tú'
                            : <ItemAgentForColumns agent={agent} />
                    }
                </div>
            }
        }
    ] : []),
    {
        id: 'actions',
        cell: ({ row }) => {
            const isFirstMeeting = row.index == 0
            if (!isFirstMeeting) return null;
            const meetingLink = _.get(row.original, 'content.link', '')
            if (!meetingLink) return null

            const onRedirecToToMeeting = () => {
                const a = document.createElement("a")
                a.href = meetingLink
                a.target = '_blank'
                a.click()
            }

            return <div className="w-full flex items-center justify-end">
                <SimpleTooltip content="Ir a la reunión">
                    <Button size="icon" variant="ghost" className="text-primary w-7 h-7" onClick={onRedirecToToMeeting}>
                        <ChevronRight size={19} />
                    </Button>
                </SimpleTooltip>
            </div>
        }
    }
]