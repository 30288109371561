import { Alert, Snackbar } from '@mui/material';
import { useRouter } from 'next/router';
import Pusher from 'pusher-js';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie, isAuth } from '../../../actions/auth';
import { isTheRolEnabled } from '../../../helpers/featureFlagHelpers';
import { handleUpdate, setSocketId } from '../../../redux/slices/crm';
import initPusher from './PusherJSInstance';

const API = process.env.API;

interface SocketHandlerProps {
    listen?: string;
    children: JSX.Element | JSX.Element[];
    isPrivate?: boolean,
    agentListen?: string
}

const PusherHandler: React.FC<SocketHandlerProps> = ({
    children,
    isPrivate,
    listen,
    agentListen
}) => {
    const router = useRouter()
    const [lastSocketId, setLastSocketId] = useState(null)
    const [pusher, setPusher] = useState<Pusher | null>(null);
    const [socketConnectedMessage, setSocketConnectedMessage] = useState<boolean>(false);
    const [beforeRoom, setBeforeRoom] = useState<string>();

    const dispatch = useDispatch();
    const globalSocketId = useSelector((state: any) => state.crm.socketId)
    const user = useMemo(() => isAuth(), [])

    useEffect(() => {
        if (!!agentListen || !!isPrivate || !getCookie('token')) return;
        let pusherInstance = initPusher()
        if (!pusherInstance) return

        (async () => pusherInstance.user.signin())()

        let socketId: string | null = null || globalSocketId

        pusherInstance.connection.bind('connected', () => {
            socketId = pusherInstance.connection.socket_id
            dispatch(setSocketId(socketId))
        })

        pusherInstance.user.bind('interaction', (response) => {
            setSocketConnectedMessage(false);
            const { socketIgnore } = response || {}
            if (!!socketIgnore && (socketIgnore === socketId) || (socketIgnore === lastSocketId)) return;
            dispatch(handleUpdate(response));
        });

        pusherInstance.user.bind('pusher:subscription_succeeded', () => {
            setSocketConnectedMessage(false);
        })

        pusherInstance.user.bind('pusher:subscription_error', () => {
            setSocketConnectedMessage(true);
        })

        setPusher(pusherInstance);
        return () => {
            if (pusherInstance) return pusherInstance.disconnect();
        }
    }, [getCookie('token'), isPrivate, user]);

    useEffect(() => {
        if (!isPrivate || !listen || !!agentListen) return;
        if (isPrivate && listen && listen !== '' && getCookie('token')) {
            let pusherInstance = initPusher()
            if (!pusherInstance) return
            const channelName = `private-listenContact-${listen}`
            let channelListen = pusherInstance.subscribe(channelName);

            let socketId: string | null | undefined = null ?? globalSocketId

            pusherInstance.connection.bind('connected', () => {
                socketId = pusherInstance?.connection.socket_id
                dispatch(setSocketId(socketId))
            })

            channelListen.bind('interaction', (response) => {
                const { socketIgnore } = response || {}
                if (socketIgnore && ((socketIgnore === socketId) || (socketIgnore === lastSocketId))) return;
                dispatch(handleUpdate(response));
            });

            channelListen.bind('pusher:subscription_succeeded', () => {
                setSocketConnectedMessage(false);
            });

            channelListen.bind('pusher:subscription_error', () => {
                setSocketConnectedMessage(true);
            });

            if (beforeRoom && beforeRoom !== '') {
                pusherInstance.unsubscribe(channelName);
            }

            setBeforeRoom(listen);
        }
    }, [listen, isPrivate]);

    useEffect(() => {
        if (!agentListen || !!listen) return;

        if (pusher && beforeRoom) pusher.unsubscribe(beforeRoom);

        const channelName = `private-agent-${agentListen}`;
        const pusherInstance = initPusher();
        if (!pusherInstance) return;

        const channelListen = pusherInstance.subscribe(channelName);

        let socketId: string | null | undefined = null ?? globalSocketId;

        pusherInstance.connection.bind('connected', () => {
            socketId = pusherInstance?.connection.socket_id;
            dispatch(setSocketId(socketId));
        });

        channelListen.bind('interaction', (response) => {
            const { socketIgnore } = response || {};
            if (socketIgnore && ((socketIgnore === socketId) || (socketIgnore === lastSocketId))) return;
            dispatch(handleUpdate(response));
        });

        channelListen.bind('pusher:subscription_succeeded', () => {
            setSocketConnectedMessage(false);
        });

        channelListen.bind('pusher:subscription_error', () => {
            setSocketConnectedMessage(true);
        });

        setBeforeRoom(agentListen);

        return () => {
            if (pusherInstance) {
                pusherInstance.unsubscribe(channelName);
            }
        };
    }, [agentListen, listen]);

    useEffect(() => {
        setLastSocketId(globalSocketId)
    }, [globalSocketId])

    return (
        <>
            {children}
            <Snackbar open={socketConnectedMessage}>
                <Alert severity="error">Volviendo a conectar...</Alert>
            </Snackbar>
        </>
    );
};

export default PusherHandler;