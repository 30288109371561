import { Chat } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import Link from "next/link";
import { getCompany } from "../../../actions/auth";
import { setGraphSelectedContact } from "@/redux/slices/graph";
import { useDispatch } from "react-redux";


export default [

    {
        field: "date",
        headerName: "Fecha",
        description: "Fecha",
        width: 150,
        renderCell: (params) => {
            return moment(params.value).format("DD/MM/YYYY");
        }
    },
    {
        field: "contentStatus",
        headerName: "Resultado",
        description: "Resultado",
        width: 150,
        renderCell: (params) => {
            let text = _.get(params, 'row.content.state')
            let resultUse
            let resultsVisit = _.get(getCompany(), 'visitResults')
            if (resultsVisit) resultsVisit.map((v) => v.code == text ? resultUse = v : '')
            if (resultUse) return resultUse.type == 'done' ? <span style={{ color: '#2e7d32' }}>{resultUse.name}</span> : <span style={{ color: '#e00' }} >{resultUse.name}</span>
            return text
        }
    },
    {
        field: "contact",
        headerName: "Contacto",
        description: "Contacto",
        width: 100,
        renderCell: (params) => {
            const dispatch = useDispatch()
            return <IconButton onClick={() => dispatch(setGraphSelectedContact(params.value))} disabled={!params.value || params.value == ""}>
                <Chat fontSize="small" sx={{ color: "#aaa" }} />
            </IconButton>
        }
    },
    {
        field: "agent",
        headerName: "Vendedor",
        description: "Vendedor",
        width: 150,
        renderCell: (params) => {
            return params.value ? `${params.value.name} ${params.value.lastname}` : '';
        }
    },
    {
        field: "contentNote",
        headerName: "Nota",
        description: "Nota",
        width: 400,
        renderCell: (params) => {
            return _.get(params, 'row.content.comment')

        }
    },
]