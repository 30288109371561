
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "@/components/ui/select";

export default function PersonalizeSelect({
    personalizedChartsOptions,
    value,
    onChange,
    personalizedChartsOptionsToMap
}) {
    if (personalizedChartsOptions.length === 0) return null;
    const isNotPersonalizeOption = !personalizedChartsOptionsToMap[value]
    const selectValue = isNotPersonalizeOption ? undefined : value;

    return (
        <Select
            key={selectValue}
            value={selectValue}
            onValueChange={onChange}
        >
            <SelectTrigger
                className={`max-w-[150px] ${isNotPersonalizeOption ? '' : 'bg-primary text-white [&>span]:text-white'} rounded-[4px] [&>span]:max-w-[100%] gap-2 rounded-r-none [&>span]:truncate text-primary border-primary h-[30px]`}
                svgClassName={`${isNotPersonalizeOption ? 'text-primary' : 'text-white opacity-100'}`}
            >
                <SelectValue
                    placeholder="Gráficas personalizadas"
                    className="max-w-[100%] truncate placeholder:truncate"
                />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {
                        personalizedChartsOptions.map(({ value, label }) => (
                            <SelectItem
                                key={value}
                                value={value}
                            >
                                {label}
                            </SelectItem>
                        ))
                    }
                </SelectGroup>
            </SelectContent>
        </Select>
    )
}