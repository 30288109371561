import { AlarmClock, X } from 'lucide-react';
import { useMemo } from 'react';
import { ComissionInterface, ComissionType } from '../../../interfaces/Interface';
import SimpleTooltip from '@/components/ui/simple-tooltip';

export interface ComissionWithSales extends Omit<ComissionInterface, 'sales'> {
    sales: {
        ticket_id: string,
        ticket_sale_id: string,
        date: string,
        date_sale: string,
        sold: number
    }[]
}

export interface ComissionItemProps {
    comission: ComissionWithSales,
    categoriesToMap: { [key: string]: ComissionType }
}

const iconsByStatus = {
    'rejected': <X size={14} className='text-red-400' />,
    'pending': <SimpleTooltip content="Pendiente">
        <AlarmClock size={14} className='text-yellow-400' />
    </SimpleTooltip>
}

export default function ComissionItem({
    comission,
    categoriesToMap
}: ComissionItemProps) {
    const { category, totalBounty, bountyCurrency, status, sales = [] } = comission;
    const categoryText = categoriesToMap?.[category]?.title || category || 'Sin categoría';

    const saleWithTicketId: ComissionWithSales['sales'] = useMemo(() => {
        return sales.filter(({ ticket_id, ticket_sale_id }) => ticket_sale_id || ticket_id)
    }, [sales]);

    const icon = iconsByStatus[status] || null;

    return (
        <div className="flex items-center w-full justify-between">
            <div className="flex items-center flex-grow overflow-hidden gap-2">
                <div className="flex items-center gap-1">
                    <p className="text-gray-700 text-[15px]">
                        {categoryText}
                    </p>
                    {icon}
                </div>
                {
                    (saleWithTicketId.length > 0) && (
                        <div className="flex flex-col gap-1 flex-1 max-w-[100%] overflow-hidden" >
                            {
                                saleWithTicketId.map(({ ticket_id, ticket_sale_id }, inx) => (
                                    <p className="text-[14px] text-gray-500 font-light truncate max-w-[100%]" key={inx}>
                                        #{ticket_sale_id || ticket_id}
                                    </p>
                                ))
                            }
                        </div>
                    )
                }
            </div>
            <div className="flex items-center gap-2">
                <p className="text-gray-500 text-[13px]">
                    <b>{totalBounty}</b>{' '}
                    {bountyCurrency}
                </p>
            </div>
        </div>
    )
}