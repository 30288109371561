import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/lib/utils"
import { Badge } from "./badge"

const chipVariants = cva(
    "flex items-center gap-1 text-[11px] py-1 rounded-lg border-[2px]",
    {
        variants: {
            variant: {
                default: "text-slate-700 border-slate-300",
                destructive:
                    "text-red-500 border-red-300",
                success:
                    "text-green-700 border-green-500",
                lates:
                    "text-orange-500 border-orange-300",
            },
        },
        defaultVariants: {
            variant: "default",
        },
    }
)

export interface BadgeGoal
    extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof chipVariants> { }


const BadgeGoal = React.forwardRef<HTMLDivElement, BadgeGoal>(
    ({ className, variant, ...props }) => {
        return <Badge variant={'outline'} className={cn(chipVariants({ variant, className }))} {...props}></Badge>
    }
)
BadgeGoal.displayName = "BadgeGoal"

export { BadgeGoal, chipVariants }
