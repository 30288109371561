import { ToggleButton } from "@mui/material"

export default function SelectTabItem({
    onChangeTab,
    activeTab,
    value,
    label,
    isLastElement,
    isFirstElement
}) {
    const isSelected = activeTab === value
    return (
        <ToggleButton
            value={value}
            onClick={() => onChangeTab(value)}
            sx={{
                py: 0.25,
                color: isSelected ? "#fff!important" : '#0080ff!important',
                borderColor: "#0080ff!important",
                background: isSelected ? "#0080ff!important" : "#fff!important",
                borderRadius: (isFirstElement && isLastElement)
                    ? '4px 4px 4px 4px' : isFirstElement
                        ? '4px 0px 0px 4px' : isLastElement
                            ? '0px 4px 4px 0px' : '0px'
            }}>
            <span className="max-w-[100px] truncate">
                {label}
            </span>
        </ToggleButton>
    )
}