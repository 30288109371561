export interface panel_item {
    content: any;
    role: string[];
    _id: string;
    title: string;
    description: string;
    template: string;
}


const panel: panel_item[] = [
    {
        "content": {
            "text": "",
            "url": "",
            "height": "0",
            "tabs": []
        },
        "role": [
            "user",
            "super",
            "comercial"
        ],
        "_id": "612eb2527b5b4e86beb50760",
        "title": "Capacitación",
        "description": "Proxima capacitación",
        "template": "training"
    },
    {
        "content": {
            "text": "",
            "url": "",
            "height": "0",
            "tabs": []
        },
        "role": [
            "super",
            "comercial"
        ],
        "_id": "612eb2527b5b4e86beb50761",
        "title": "Rendimiento del grupo",
        "description": "Rendimiento del grupo",
        "template": "performanceGroup"
    },
    {
        "content": {
            "text": "",
            "url": "",
            "height": "0",
            "tabs": []
        },
        "role": [
            "user"
        ],
        "_id": "612eb27a7b5b4e86beb50763",
        "title": "Última llamada",
        "description": "ultima llamada",
        "template": "lastcall"
    },
    {
        "content": {
            "text": "",
            "url": "https://crmplus.zoho.com/reports/open-view/2000511000004533607?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22email%22%3D'%email%'",
            "height": "300",
            "tabs": []
        },
        "role": [
            "user",
            "super",
            "comercial"
        ],
        "_id": "612eb2c27b5b4e86beb50764",
        "title": "Meta de esta semana",
        "description": "",
        "template": "performance"
    },
    {
        "content": "",
        "role": [
            "user",
            "super",
            "comercial"
        ],
        "_id": "612eb2c27b5b4e86beb5aifhe",
        "title": "Rendimiento",
        "description": "",
        "template": "long"
    },
    {
        "content": {
        },
        "role": [
            "user",
            "super",
            "comercial"
        ],
        "_id": "612eb2527b5b4e86b5b40732",
        "title": "Reuniónes",
        "description": "Reuniónes con clientes",
        "template": "meetings"
    },
    {
        "content": {
            "text": "",
            "url": "https://crmplus.zoho.com/reports/open-view/2000511000004637454?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22email%22%3D'%email%'",
            "height": "250",
            "tabs": []
        },
        "role": [
            "user",
            "super",
            "comercial"
        ],
        "_id": "612eb2e37b5b4e86beb50765",
        "title": "Meta",
        "description": "",
        "template": "meta"
    },
    {
        "content": {
            "tabs": [
                {
                    "_id": "612eb35c7b5b4e86beb50768",
                    "title": "Grupos",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000003784987?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22company%22%20%3D%20%27%company%%27",
                    "height": "1800px",
                    "height-device": "80vh"
                },
                {
                    "_id": "612eb35c7b5b4e86beb50769",
                    "title": "Vendedor",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000003784015?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22company%22%20%3D%20%27%company%%27",
                    "height": "3000px",
                    "height-device": "80vh"
                },
                {
                    "_id": "612eb35c7b5b4e86beb5076a",
                    "title": "Diaria",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000003684117?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22company%22%20%3D%20%27%company%%27",
                    "height": "1800px",
                    "height-device": "80vh"
                },
                {
                    "_id": "612eb35c7b5b4e86beb5076b",
                    "title": "Semanal",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000003785519?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22company%22%20%3D%20%27%company%%27",
                    "height": "1800px",
                    "height-device": "80vh"
                },
                {
                    "_id": "612eb35c7b5b4e86beb5076c",
                    "title": "Mensual",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000003826601?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22company%22%20%3D%20%27%company%%27",
                    "height": "1800px",
                    "height-device": "80vh"
                },
                {
                    "_id": "612eb35c7b5b4e86beb5076d",
                    "title": "Ventas",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000004106084?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22company%22%20%3D%20%27%company%%27",
                    "height": "1800px",
                    "height-device": "80vh"
                },
                {
                    "_id": "612eb35c7b5b4e86beb50768",
                    "title": "Grupos",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000003784987?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22company%22%20%3D%20%27%company%%27",
                    "height": "1800px",
                    "height-device": "80vh",
                    "type": "facturacion"
                },
                {
                    "_id": "612eb3d47b5b4e86beb5077c",
                    "title": "Vendedor",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000008304244?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22company%22%20%3D%20%27%company%%27",
                    "height": "3000px",
                    "height-device": "80vh",
                    "type": "facturacion"
                },
                {
                    "_id": "612eb3da7b5b4e86beb50783",
                    "title": "Diaria",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000008288253?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22company%22%20%3D%20%27%company%%27",
                    "height": "1800px",
                    "height-device": "80vh",
                    "type": "facturacion"
                },
                {
                    "_id": "612eb3da7b5b4e86beb50784",
                    "title": "Semanal",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000008303539?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22company%22%20%3D%20%27%company%%27",
                    "height": "1800px",
                    "height-device": "80vh",
                    "type": "facturacion"
                },
                {
                    "_id": "612eb3da7b5b4e86beb50785",
                    "title": "Mensual",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000008303949?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22company%22%20%3D%20%27%company%%27",
                    "height": "1800px",
                    "height-device": "80vh",
                    "type": "facturacion"
                },

                {
                    "_id": "612eb35c7b5b4e86beb5076d",
                    "title": "Facturacion",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000006447486?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22company%22%20%3D%20%27%company%%27",
                    "height": "1800px",
                    "height-device": "80vh",
                    "type": "facturacion"
                },

                {
                    "_id": "612eb35c7b5b4e86beb5076e",
                    "title": "Clientes",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000004174302?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22company%22%20%3D%20%27%company%%27",
                    "height": "1800px",
                    "height-device": "80vh"
                },
                {
                    "_id": "612eb35c7b5b4e86beb5076e",
                    "title": "Clientes",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000004174302?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22company%22%20%3D%20%27%company%%27",
                    "height": "1800px",
                    "height-device": "80vh",
                    "type": "facturacion"
                }
            ]
        },
        "role": [
            "comercial"
        ],
        "_id": "612eb35c7b5b4e86beb50767",
        "title": "Tab Gestion",
        "description": "Lista de Reportes",
        "template": "tab"
    },
    {
        "content": {
            "tabs": [
                {
                    "_id": "612eb3d47b5b4e86beb5077c",
                    "title": "Vendedor",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000003784015?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22group%22%20%3D%20%27%group%%27",
                    "height": "3000px",
                    "height-device": "80vh"
                },
                {
                    "_id": "612eb3d47b5b4e86beb5077d",
                    "title": "Diaria",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000003684117?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22group%22%20%3D%20%27%group%%27",
                    "height": "1400px",
                    "height-device": "80vh"
                },
                {
                    "_id": "612eb3d47b5b4e86beb5077e",
                    "title": "Semanal",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000003785519?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22group%22%20%3D%20%27%group%%27",
                    "height": "1800px",
                    "height-device": "80vh"
                },
                {
                    "_id": "612eb3d47b5b4e86beb5077f",
                    "title": "Mensual",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000003826601?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22group%22%20%3D%20%27%group%%27",
                    "height": "1800px",
                    "height-device": "80vh"
                },
                {
                    "_id": "612eb3d47b5b4e86beb50780",
                    "title": "Ventas",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000004106084?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22group%22%20%3D%20%27%group%%27",
                    "height": "1800px",
                    "height-device": "80vh"
                },
                {
                    "_id": "612eb3d47b5b4e86beb5077c",
                    "title": "Vendedor",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000008304244?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22group%22%20%3D%20%27%group%%27",
                    "height": "3000px",
                    "height-device": "80vh",
                    "type": "facturacion"
                },
                {
                    "_id": "612eb3da7b5b4e86beb50783",
                    "title": "Diaria",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000008288253?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22group%22%20%3D%20%27%group%%27",
                    "height": "1800px",
                    "height-device": "80vh",
                    "type": "facturacion"
                },
                {
                    "_id": "612eb3da7b5b4e86beb50784",
                    "title": "Semanal",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000008303539?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22group%22%20%3D%20%27%group%%27",
                    "height": "1800px",
                    "height-device": "80vh",
                    "type": "facturacion"
                },
                {
                    "_id": "612eb3da7b5b4e86beb50785",
                    "title": "Mensual",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000008303949?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22group%22%20%3D%20%27%group%%27",
                    "height": "1800px",
                    "height-device": "80vh",
                    "type": "facturacion"
                },
                {
                    "_id": "612eb35c7b5b4e86beb5076d",
                    "title": "Facturacion",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000006447486?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22group%22%20%3D%20%27%group%%27",
                    "height": "1800px",
                    "height-device": "80vh",
                    "type": "facturacion"
                },
                {
                    "_id": "612eb3d47b5b4e86beb50781",
                    "title": "Clientes",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000004174302?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22group%22%20%3D%20%27%group%%27",
                    "height": "1800px",
                    "height-device": "80vh"
                },

                {
                    "_id": "612eb3d47b5b4e86beb50781",
                    "title": "Clientes",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000004174302?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22group%22%20%3D%20%27%group%%27",
                    "height": "1800px",
                    "height-device": "80vh",
                    "type": "facturacion"
                }
            ]
        },
        "role": [
            "super"
        ],
        "_id": "612eb3d47b5b4e86beb5077b",
        "title": "Tab Gestion",
        "description": "Lista de Reportes",
        "template": "tab"
    },
    {
        "content": {
            "tabs": [
                {
                    "_id": "612eb3da7b5b4e86beb50783",
                    "title": "Diaria",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000003684117?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22email%22%20%3D%20%27%email%%27",
                    "height": "1800px",
                    "height-device": "80vh"
                },
                {
                    "_id": "612eb3da7b5b4e86beb50784",
                    "title": "Semanal",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000003785519?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22email%22%20%3D%20%27%email%%27",
                    "height": "1800px",
                    "height-device": "80vh"
                },
                {
                    "_id": "612eb3da7b5b4e86beb50785",
                    "title": "Mensual",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000003826601?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22email%22%20%3D%20%27%email%%27",
                    "height": "1800px",
                    "height-device": "80vh"
                },
                {
                    "_id": "612eb3da7b5b4e86beb50783",
                    "title": "Diaria",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000008288253?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22email%22%20%3D%20%27%email%%27",
                    "height": "1800px",
                    "height-device": "80vh",
                    "type": "facturacion"
                },
                {
                    "_id": "612eb3da7b5b4e86beb50784",
                    "title": "Semanal",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000008303539?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22email%22%20%3D%20%27%email%%27",
                    "height": "1800px",
                    "height-device": "80vh",
                    "type": "facturacion"
                },
                {
                    "_id": "612eb3da7b5b4e86beb50785",
                    "title": "Mensual",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000008303949?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22email%22%20%3D%20%27%email%%27",
                    "height": "1800px",
                    "height-device": "80vh",
                    "type": "facturacion"
                },
                {
                    "_id": "612eb3da7b5b4e86beb50786",
                    "title": "Ventas",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000004106084?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22email%22%20%3D%20%27%email%%27",
                    "height": "1800px",
                    "height-device": "80vh"
                },
                {
                    "_id": "612eb35c7b5b4e86beb5076d",
                    "title": "Facturacion",
                    "url": "https://crmplus.zoho.com/reports/open-view/2000511000006447486?ZOHO_CRITERIA=%22Usuarios%20VENTIA%22.%22email%22%20%3D%20%27%email%%27",
                    "height": "1800px",
                    "height-device": "80vh",
                    "type": "facturacion"
                }
            ]
        },
        "role": [
            "user"
        ],
        "_id": "612eb3da7b5b4e86beb50782",
        "title": "Tab Gestion",
        "description": "Lista de Reportes",
        "template": "tab"
    }
]

export default panel