import { getCompany } from '@/actions/auth';
import GraphPanel from '@/components/graph/GraphPanel';
import { useMemo } from 'react';
import { allChartsType } from '../../constants/chartsTypes';
import { getStatusTagColorToHexadecimal } from '@/helpers/tagColorToPalette';

export default function Personalize({ user, dashboardChart }) {
    const { name, charts } = dashboardChart || {}
    const company = useMemo(() => getCompany(), []);

    const graphs = useMemo(() => {
        return charts.map(({ chartId }) => allChartsType({ company })[chartId]).flat().filter(Boolean)
    }, [charts, dashboardChart])

    console.log((company?.statusTags || []).filter((tag) => tag.active).map((statusTag) => ({
        key: statusTag.code,
        name: statusTag.name
    })), 'HOLAAAA MUNDOOOOOOOOOOOOOO');

    return (
        <GraphPanel
            title={name}
            useAsUser={user}
            graphs={graphs}
        />
    )
}
