import { Button } from '@/components/ui/button'
import SimpleTooltip from '@/components/ui/simple-tooltip'
import { isTheRolEnabled } from '@/helpers/featureFlagHelpers'
import { ToggleButtonGroup } from '@mui/material'
import _ from 'lodash'
import { Pencil } from 'lucide-react'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { defaultTabs } from '../constants/charts'
import LoadingTabsList from './loading-tabs-list'
import PersonalizeSelect from './personalize-select'
import SelectTabItem from './select-tab-item'

interface ITabsListProps {
    activeTab: string,
    onChangeTab: (value: string) => void
    personalizedChartsOptions: Array<{
        chartId: string,
        index: number,
        name: string,
        hidden?: boolean,
        show?: boolean,
        _id: string,
        referenceToDefaultChart?: string
    }>
}

export default function TabsList({
    activeTab,
    onChangeTab,
    personalizedChartsOptions
}: ITabsListProps) {
    const isLoadingFolders = useSelector((state: any) => state.config.isLoadingFolders)
    const router = useRouter()

    const { withRef, withoutRef } = useMemo(() => {
        let withRef: any[] = [];
        let withoutRef: any[] = [];

        personalizedChartsOptions.forEach((c) => {
            if (c.referenceToDefaultChart) return withRef.push(c);
            withoutRef.push(c);
        })

        return { withRef, withoutRef }
    }, [personalizedChartsOptions])

    const optionsTabs = useMemo(() => {
        const withRefToMap = _.keyBy(withRef, 'referenceToDefaultChart');

        let chartsTabs: any[] = [...defaultTabs].filter((folder) => {
            const editedValue = withRefToMap[folder.ref];

            if (!editedValue) return true;
            if (editedValue.hasOwnProperty('show') && !editedValue.show) return false;
            return !withRefToMap[folder.ref]?.hidden;

        }).map((folder) => {
            const editedValue = withRefToMap[folder.ref];
            if (!editedValue) return folder;

            return {
                label: editedValue.name || folder.label,
                value: editedValue._id || folder.value
            }
        })

        chartsTabs = [
            ...chartsTabs,
            ...withoutRef.map(({ name, _id: folderId }) => ({
                label: name,
                value: folderId
            }))]
        return chartsTabs
    }, [personalizedChartsOptions, withRef, withoutRef])

    const firstFolders = useMemo(() => [...optionsTabs].splice(0, 4), [optionsTabs]);
    const selectFoldersData = useMemo(() => [...optionsTabs].splice(4, optionsTabs.length), [optionsTabs]);
    const personalizedChartsOptionsToMap = useMemo(() => _.keyBy(withoutRef, '_id'), [withoutRef, withRef]);

    const folderSelected = personalizedChartsOptionsToMap[activeTab]

    const handleEditFolder = () => {
        if (!folderSelected) return;
        router.push(`/company/charts/${folderSelected._id}`)
    }

    if (isLoadingFolders) return <LoadingTabsList />

    return (
        <div className='flex flex-grow gap-1 items-center'>
            {
                isTheRolEnabled(['users_manager']) && !!folderSelected && (
                    <SimpleTooltip content={`Editar carpeta (${folderSelected.name})`}>
                        <Button size={'icon'} variant={'outline-primary'} className='h-[30px] rounded-[4px] w-[30px]' onClick={handleEditFolder}>
                            <Pencil size={16} />
                        </Button>
                    </SimpleTooltip>
                )
            }
            <div className='flex items-center'>
                <PersonalizeSelect
                    value={activeTab}
                    onChange={onChangeTab}
                    personalizedChartsOptions={selectFoldersData}
                    personalizedChartsOptionsToMap={personalizedChartsOptionsToMap}
                />
                <ToggleButtonGroup value={activeTab} exclusive>
                    {
                        firstFolders.map(({ label, value }, inx) => (
                            <SelectTabItem
                                key={value}
                                isFirstElement={inx === 0 && selectFoldersData.length === 0}
                                isLastElement={optionsTabs.length === 1 || inx === firstFolders.length - 1}
                                activeTab={activeTab}
                                label={label}
                                value={value}
                                onChangeTab={onChangeTab}
                            />
                        ))
                    }
                </ToggleButtonGroup>
            </div>
        </div>
    )
}