import { getComissionByPeriod, TotalBountyByCurrency } from "@/actions/comission";
import { ComissionInterface } from "@/interfaces/Interface";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from 'react';


export interface ComissionParams {
    page: number,
    limit: number,
    status?: 'pending' | 'rejected' | 'confirmed',
    user?: string,
    group?: string
};

export function useGetUserComissionsPeriod({ externalParams }: { externalParams?: ComissionParams }) {
    const [comissions, setComissions] = useState<ComissionInterface[]>([]);
    const [countDocuments, setCountDocuments] = useState<number>(0);
    const [totalBountyByCurrency, setTotalBountyByCurrency] = useState<TotalBountyByCurrency>({
        confirmed: {},
        pending: {}
    });

    const [params, setParams] = useState<ComissionParams>({
        page: 1,
        limit: 50
    });

    const useParams = externalParams || params

    const { data, isLoading, isError } = useQuery({
        queryKey: ['comissions-by-period', useParams],
        queryFn: () => getComissionByPeriod(useParams)
    });

    const onChangeParams = (params: Partial<ComissionParams>) => {
        setParams(lastValue => ({ ...lastValue, ...params, page: 1 }));
    }

    useEffect(() => {
        if (!data || !data?.comissions) return;

        const newComissions = data.comissions;
        setCountDocuments(data?.countDocuments || 0);
        setTotalBountyByCurrency(data.totalBountyByCurrency)

        setComissions(lastValue => useParams.page == 1
            ? newComissions
            : [...lastValue, ...newComissions]
        );
    }, [data])

    return {
        totalBountyByCurrency,
        params: useParams,
        onChangeParams,
        countDocuments,
        comissions,
        isLoading,
        isError
    }
};