import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid, esES } from "@mui/x-data-grid";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getWeekGoal } from "../../actions/comercial/metas";
import FilterMultiSelect from "../reports/FilterMultiSelect";
import NoRows from "../reports/NoRows";
import { IconButton, Tooltip } from "@mui/material";
import {
    Download,
    Group,
    Person,
    FilterAlt,
    DateRange,
    CheckCircleOutline,
} from "@mui/icons-material";
import { downloadGoal } from "../../actions/comercial/metas";
import { useQuery } from "@tanstack/react-query";
import useUserFilterAvanced from "../reports/UserFilterAvanced/useUserFilterAvanced";
import UserFilterAvanced from "../reports/UserFilterAvanced";
import { getCookie } from "../../actions/auth";
import PersonalizedMonthModal from "./PersonalizedMonthModal";
import _ from "lodash";
import FilterSelect from "../reports/FilterSelect";
var API = process.env.API;

interface GoalProps {
    seller: boolean;
    user?: string | string[];
    group?: boolean;
}

const Goal = ({ seller, user, group }: GoalProps) => {
    const [showFilter, toggleFilter] = useState("none");
    const [grouped, setGrouped] = useState(!!group);
    const [filters, setFilters] = useState({
        user: ["all"],
        weeks: ["all"],
        month: ["0"],
    });
    const hookFilterUserAvanced = useUserFilterAvanced({ useAsUserInit: user });
    const [{ usersFilter, useAsUser }, { setUseAsUser }] =
        hookFilterUserAvanced;

    const [openPersonalizedMonth, setOpenPersonalizedMonth] = useState(false);
    const [activePersonalizedMonth, setActivePersonalizedMonth] = useState<
        false | string
    >(false);

    useEffect(() => {
        setUseAsUser(user);
    }, [user]);

    let getSumValue = (goalWeek: any) => {
        let goal = _.get(goalWeek, 'goal[0]', {})
        let goalSale = _.get(goalWeek, 'goal[1]', {})

        let goal_prize = parseInt(_.get(goalWeek, 'goal[0].prize', 0), 10)
        let goal_puls_prize = parseInt(_.get(goalWeek, 'goal[1].prize', 0), 10)
        let goal_plus_sales = parseInt(_.get(goalWeek, 'goal[1].sale', 0))
        let goal_plus_sales_value = parseInt(_.get(goalWeek, 'goal[1].saleValue', 0))
        // if()
        if (goal.contacts <= goal.contactsValue && goal.tracing <= goal.tracingValue && goalSale.sale <= goalSale.saleValue) {
            let isValid = [goal_prize, goal_puls_prize, goal_plus_sales, goal_plus_sales_value].filter((valueINT) => isNaN(valueINT)).length == 0
            if (!isValid) return 0
            return goal_prize + (goal_puls_prize * (goal_plus_sales_value - goal_plus_sales + 1))
        } else if (goal.contacts <= goal.contactsValue && goal.tracing <= goal.tracingValue && goal.sale <= goal.saleValue) {
            return goal_prize
        }
        // return Object.keys(params.row.goal[0]).join(', ')
        return 0
    }

    const precisionRound = (number: number, precision: number) => {
        var factor = Math.pow(10, precision);
        return Math.round(number * factor) / factor;
    }

    let getRows = (list) => {
        let input =
            filters.weeks[0] == "all"
                ? list
                : list.filter((c) => filters.weeks.includes(c.title));
        let result = [];
        let keys = [];
        if (grouped) {
            input.map((v) => {
                let key = v.month + "-" + v.title;
                if (keys.includes(key)) {
                    let index = keys.indexOf(key);
                    for (let i = 0; i < result[index].goal.length; i++) {
                        result[index].goal[i].contacts += v.goal[i].contacts;
                        result[index].goal[i].contactsValue +=
                            v.goal[i].contactsValue;
                        result[index].goal[i].sale += v.goal[i].sale;
                        result[index].goal[i].saleValue += v.goal[i].saleValue;
                        result[index].goal[i].tracing += v.goal[i].tracing;
                        result[index].goal[i].tracingValue +=
                            v.goal[i].tracingValue;
                    }
                } else {

                    // keys.push(key); Wrong is adding always at end
                    // Add based on sorted order
                    let index = keys.findIndex((k: string) => k > key)
                    if (index == -1) {
                        keys.push(key)
                        index = keys.length - 1
                    } else {
                        keys.splice(index, 0, key)
                    }


                    let newObj = JSON.parse(JSON.stringify(v));
                    newObj.user.name = "Vendedores";
                    newObj.user.lastname = "Agrupados";
                    // result.push(newObj);Wrong is adding always at end
                    // Add based on sorted order
                    result.splice(index, 0, newObj)
                }
            });
        } else {
            result = [...input];
        }
        let actualKey: any;
        let actualObject: any;
        let total_lists = [];
        for (let i = 0; i <= result.length && result.length != 0; i++) {
            if (!actualKey) {
                actualKey =
                    result[i].user.name +
                    " " +
                    result[i].user.lastname +
                    " " +
                    result[i].month +
                    " " +
                    result[i].year;
                actualObject = JSON.parse(JSON.stringify(result[i]));
                actualObject._id =
                    result[i].user.name +
                    " " +
                    result[i].user.lastname +
                    " " +
                    result[i].month +
                    " " +
                    result[i].year;
                actualObject.id = actualObject._id;
                actualObject.bold = true;
                actualObject.obtenido = getSumValue(actualObject)
            } else {
                if (
                    i == result.length ||
                    actualKey !=
                    result[i].user.name +
                    " " +
                    result[i].user.lastname +
                    " " +
                    result[i].month +
                    " " +
                    result[i].year
                ) {
                    actualObject.title =
                        "Mes " + actualObject.month + "/" + actualObject.year;
                    total_lists.push([i, actualObject]);
                    if (result.length != i) {
                        actualObject = JSON.parse(JSON.stringify(result[i]));
                        actualKey =
                            result[i].user.name +
                            " " +
                            result[i].user.lastname +
                            " " +
                            result[i].month +
                            " " +
                            result[i].year;
                        actualObject._id =
                            result[i].user.name +
                            " " +
                            result[i].user.lastname +
                            " " +
                            result[i].month +
                            " " +
                            result[i].year;
                        actualObject.id = actualObject._id;
                        actualObject.bold = true;
                        actualObject.obtenido = getSumValue(actualObject)
                    }
                } else {
                    for (let ii = 0; ii < result[i].goal.length; ii++) {
                        actualObject.goal[ii].contacts +=
                            result[i].goal[ii].contacts;
                        actualObject.goal[ii].contactsValue +=
                            result[i].goal[ii].contactsValue;
                        actualObject.goal[ii].sale += result[i].goal[ii].sale;
                        actualObject.goal[ii].saleValue +=
                            result[i].goal[ii].saleValue;
                        actualObject.goal[ii].tracing +=
                            result[i].goal[ii].tracing;
                        actualObject.goal[ii].tracingValue +=
                            result[i].goal[ii].tracingValue;

                        if (!isNaN(parseInt(actualObject.goal[ii].prize)) && !isNaN(parseInt(result[i].goal[ii].prize))) actualObject.goal[ii].prize = parseInt(actualObject.goal[ii].prize) + parseInt(result[i].goal[ii].prize)
                        if (!isNaN(parseInt(actualObject.goal[ii].prize_plus)) && !isNaN(parseInt(result[i].goal[ii].prize_plus))) actualObject.goal[ii].prize_plus = parseInt(actualObject.goal[ii].prize_plus) + parseInt(result[i].goal[ii].prize_plus)
                    }
                    actualObject.obtenido += getSumValue(result[i])
                    if (actualObject.start > result[i].start) {
                        actualObject.start = result[i].start + "";
                    }
                    if (actualObject.end < result[i].end) {
                        actualObject.end = result[i].end + "";
                    }
                }
            }
        }
        total_lists.map((t, index_total) => {
            result.splice(t[0] + index_total, 0, t[1]);
        });
        return result;
    };

    let reload = async (optionRetive = "calculate") => {
        let result = await getWeekGoal(
            optionRetive,
            usersFilter,
            false,
            filters.month.length == 0 ? ["0"] : filters.month,
            false,
            grouped
        );
        let list = [...result.goals.map((r) => ({ ...r, id: r._id }))].sort(
            (a, b) => {
                if (a.year != b.year)
                    return parseInt(a.year) - parseInt(b.year);
                if (a.month != b.month)
                    return parseInt(a.month) - parseInt(b.month);
                if (a.user._id != b.user._id)
                    return `${a.user.name} ${a.user.lastname}`.localeCompare(
                        `${b.user.name} ${b.user.lastname}`
                    );
                return (
                    new Date(a.start).getTime() - new Date(b.start).getTime()
                );
            }
        );
        return { list, users: result.users };
    };

    const handelMonthSelect = (value) => {
        setFilters({
            ...filters,
            month: [value],
        });
        setActivePersonalizedMonth(false);
    };

    const handlePersonalizedMonth = (value: string | false) => {
        if (value === false) {
            setOpenPersonalizedMonth(false);
        } else {
            setActivePersonalizedMonth(value);
            setOpenPersonalizedMonth(false);
            setFilters({ ...filters, month: [value] });
        }
    };

    var handleExportar = () => {
        let user_query = usersFilter
            ? usersFilter
            : user
        let months = filters.month.length == 0 ? ["0"] : filters.month;
        let windowMe: any = window;
        if (windowMe.ReactNativeWebView) {
            let reactNativeWebView: any = windowMe.ReactNativeWebView;
            let query = [];

            if (user_query) {
                query.push(`user=${user_query}`);
            }

            if (months) {
                query.push(`months=${months.join(",")}`);
            }
            let url = `${API}/weekgoal/download${query.length > 0 ? "?" + query.join("&") : ""
                }`;
            let authorization = "JWT " + getCookie("token");
            try {
                return reactNativeWebView.postMessage(
                    JSON.stringify({
                        type: "download",
                        title: "metas.xlsx",
                        url,
                        authorization,
                        method: "GET",
                    }),
                    "*"
                );
            } catch (err) {
                return windowMe.postMessage(
                    JSON.stringify({
                        type: "download",
                        title: "metas.xlsx",
                        url,
                        authorization,
                        method: "GET",
                    }),
                    "*"
                );
            }
        }
        downloadGoal("calculate", user_query, false, months, grouped).then(
            async (response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                if (windowMe.ReactNativeWebView) {
                    let reactNativeWebView: any = windowMe.ReactNativeWebView;
                    // new Blob([response.data]).text().then((result )=> {

                    //     return reactNativeWebView.postMessage(JSON.stringify({data: url, type: 'download', title: "metas.xlsx"}), '*')
                    // })
                    let query = [];

                    if (user_query) {
                        query.push(`user=${user}`);
                    }

                    if (months) {
                        query.push(`months=${months.join(",")}`);
                    }
                    let url = `${API}/weekgoal/download${query.length > 0 ? "?" + query.join("&") : ""
                        }`;
                    let authorization = "JWT " + getCookie("token");

                    // var render = new FileReader()
                    // render.readAsDataURL(new Blob([response.data]))
                    // render.onloadend = function() {
                    //     var base64data = render.result;
                    //     // console.log(base64data);
                    //     return reactNativeWebView.postMessage(JSON.stringify({data: base64data, type: 'download', title: "metas.xlsx", url, authorization}), '*')
                    // }
                    return;
                }
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "metas.xlsx"); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
        );
    };
    let queryCacheResult = useQuery({
        queryKey: [
            "goals-cache",
            usersFilter,
            false,
            filters.month.length == 0 ? ["0"] : filters.month,
        ],
        queryFn: () => reload("cache")
    });

    let queryResult = useQuery({
        queryKey: [
            "goals",
            usersFilter,
            false,
            filters.month.length == 0 ? ["0"] : filters.month,
        ],
        queryFn: () => reload(),
        enabled: !!queryCacheResult.data
    });

    let resultQuery = queryResult.data ? queryResult : queryCacheResult;
    let prize = 0;
    let salePlus = 0;
    let prizePlus = 0;
    if (resultQuery.data && resultQuery.data.list) {
        resultQuery.data.list.map((g) => {
            if (
                !(
                    !g.goal[0]?.prize ||
                    g.goal[0]?.prize == 0 ||
                    g.goal[0]?.prize == "" ||
                    g.goal[0]?.prize == "0"
                )
            ) {
                prize++;
            }
            if (
                !(
                    !g.goal[1].prize ||
                    g.goal[1].prize == 0 ||
                    g.goal[1].prize == "" ||
                    g.goal[1].prize == "0"
                )
            ) {
                prizePlus++;
            }
            if (
                !(
                    !g.goal[1].sale ||
                    g.goal[1].sale == 0 ||
                    g.goal[1].sale == "" ||
                    g.goal[1].sale == "0"
                )
            ) {
                salePlus++;
            }
        });
    }


    return (
        <Box
            sx={{
                "& .header-meta, .MuiDataGrid-columnsContainer": {
                    backgroundColor: "#0080ff",
                    color: "#fff",
                },
                "& .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row:nth-child(2n)":
                {
                    backgroundColor: "#efefef",
                },
                "& .header-meta .MuiDataGrid-columnHeaderTitle": {
                    fontWeight: 600,
                },
                "& .MuiDataGrid-columnHeaderWrapper ": {
                    height: 20,
                },
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <PersonalizedMonthModal
                open={openPersonalizedMonth}
                onResult={handlePersonalizedMonth}
            />
            {/* <Stack 
                direction="row" 
                justifyContent='flex-start' 
                sx={{overflowY: 'hidden', overflowX: 'auto', height: '60px', width: '100%', pr: 1}}> */}
            <Grid
                sx={{
                    "@media (max-width: 479px)": { display: "block" },
                    display: "none",
                }}
            >
                <Button
                    variant="outlined"
                    sx={{ mb: 2, textTransform: "none" }}
                    onClick={() =>
                        toggleFilter(showFilter == "none" ? "flex" : "none")
                    }
                    endIcon={<FilterAlt />}
                >
                    Mostrar filtros
                </Button>
            </Grid>
            <Box
                py={1}
                sx={{
                    "@media (max-width: 479px)": {
                        display: showFilter,
                        flexDirection: "column",
                    },
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                }}
            >
                {/* {seller &&
            <Grid item><FilterUser
                useEmail={false}
                title="Vendedor"
                value={filters.user}
                setValue={(value) => setFilters({...filters, user: value})}
                users={user ? userList.filter((u) => typeof user == "string" ? u._id == user : user.includes(u._id)) : userList}
                roles={[]}
            /></Grid>
            } */}
                {seller && <UserFilterAvanced hook={hookFilterUserAvanced} />}
                <Grid item>
                    <FilterSelect
                        title="Mes"
                        value={filters.month[0]}
                        setValue={handelMonthSelect}
                        idKey="id"
                        getName={(value) => value.name}
                        disableAll
                        forceTextValue={
                            activePersonalizedMonth
                                ? activePersonalizedMonth
                                : undefined
                        }
                        list={[
                            {
                                id: "-3",
                                name: _.startCase(moment().add(-3, 'month').format('MMMM')),
                            },
                            {
                                id: "-2",
                                name: _.startCase(moment().add(-2, 'month').format('MMMM')),
                            },
                            {
                                id: "-1",
                                name: `Mes anterior (${_.startCase(moment().add(-1, 'month').format('MMMM'))})`,
                            },
                            {
                                id: "0",
                                name: `Este Mes (${_.startCase(moment().format('MMMM'))})`,
                            },
                            {
                                id: "1",
                                name: `Siguiente mes (${_.startCase(moment().add(1, 'month').format('MMMM'))})`,
                            },
                            {
                                id: "2",
                                name: _.startCase(moment().add(2, 'month').format('MMMM')),
                            },
                        ]}
                        buttons={[
                            {
                                title: "Personalizado",
                                action: () => setOpenPersonalizedMonth(true),
                                icon: <DateRange />,
                                active: !!activePersonalizedMonth,
                            },
                        ]}
                    />
                </Grid>
                <Grid item>
                    <FilterMultiSelect
                        title="Semana"
                        value={filters.weeks}
                        setValue={(value) => {
                            setFilters({ ...filters, weeks: value })
                        }
                        }
                        idKey="name"
                        getName={(value) => value.name}
                        list={[
                            {
                                id: 1,
                                name: "Semana 1",
                            },
                            {
                                id: 2,
                                name: "Semana 2",
                            },
                            {
                                id: 3,
                                name: "Semana 3",
                            },
                            {
                                id: 4,
                                name: "Semana 4",
                            },
                        ]}
                    />
                </Grid>
                {/* {seller && (
            <Grid item justifyContent={'center'} alignSelf={'center'}>
                <FormControlLabel control={<Checkbox value={group} onChange={(_, check) => setGroup(check)}/>} label="Agrupar vendedores" />
            </Grid>
            )} */}
                {!!group && (
                    <Grid item>
                        <Button
                            sx={{ mt: "10px" }}
                            variant={"outlined"}
                            onClick={() => setGrouped(!grouped)}
                            endIcon={grouped ? <Group /> : <Person />}
                        >
                            {" "}
                            {grouped ? "Desagrupar" : "Agrupar"}
                        </Button>
                    </Grid>
                )}
                <Grid
                    item
                    sx={{
                        "@media (max-width: 479px)": { display: "none" },
                        display: "block",
                    }}
                >
                    <Tooltip
                        onClick={handleExportar}
                        style={{ marginTop: "10px" }}
                        title="Exportar metas"
                    >
                        <IconButton color="inherit">
                            <Download />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid
                    item
                    sx={{
                        "@media (max-width: 479px)": { display: "block" },
                        display: "none",
                    }}
                >
                    <Button
                        endIcon={<Download />}
                        onClick={handleExportar}
                        style={{ marginTop: "10px" }}
                        title="Descargar metas"
                        variant="outlined"
                    >
                        {" "}
                        Exportar
                    </Button>
                </Grid>
            </Box>
            <DataGrid
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                components={{
                    NoRowsOverlay: NoRows("Sin meta"),
                }}
                columnVisibilityModel={{
                    user: seller,
                    prize: prize > 0 && !grouped,
                    prize_plus: prizePlus > 0 && !grouped,
                    sales_plus: salePlus > 0,
                    a_cobrar: prize > 0 && !grouped,
                }}
                columns={[
                    {
                        field: "user",
                        headerName: "Vendedor",
                        width: 200,
                        renderCell: (params) => {
                            if (params.row.bold) {
                                return (
                                    <b>
                                        {params.value.name +
                                            " " +
                                            params.value.lastname}
                                    </b>
                                );
                            }
                            return (
                                params.value.name + " " + params.value.lastname
                            );
                        },
                    },
                    {
                        field: "title",
                        headerName: "Titulo",
                        width: 125,
                        renderCell: (params) => {
                            if (params.row.bold) {
                                return <b>Total {params.value}</b>;
                            }
                            return params.value;
                        },
                    },
                    {
                        field: "fecha",
                        headerName: "Fecha",
                        width: 150,
                        renderCell: (params) => {
                            let string_result =
                                moment(params.row.start).utc(false)
                                    .format("DD/MM")
                                + " al " +
                                moment(params.row.end)
                                    .utcOffset(params.row.offset || -180)
                                    .format("DD/MM");
                            return params.row.bold ? (
                                <b>{string_result}</b>
                            ) : (
                                string_result
                            );
                        },
                    },
                    {
                        field: "contacts",
                        headerName: "Contactos",
                        width: 110,
                        renderCell: (params) => {
                            return (
                                <b>
                                    {params.row.goal
                                        ? params.row.goal[0].contactsValue +
                                        "/" +
                                        params.row.goal[0].contacts
                                        : "0/0"}
                                </b>
                            );
                        },
                    },
                    {
                        field: "tracings",
                        width: 130,
                        headerName: "Seguimientos",
                        renderCell: (params) => {
                            return (
                                <b>
                                    {params.row.goal
                                        ? params.row.goal[0].tracingValue +
                                        "/" +
                                        params.row.goal[0].tracing
                                        : "0/0"}
                                </b>
                            );
                        },
                    },
                    {
                        field: "sales",
                        width: 150,
                        headerName: "Ventas",
                        renderCell: (params) => {
                            return (
                                <b>
                                    {params.row.goal
                                        ? precisionRound(params.row.goal[0].saleValue, 2) +
                                        "/" +
                                        precisionRound(params.row.goal[0].sale, 2)
                                        : "0/0"}
                                </b>
                            );
                        },
                    },
                    {
                        field: "prize",
                        width: 100,
                        headerName: "Premio",
                        // renderCell: (params) => {
                        //     return params.row.bold ? (
                        //         <></>
                        //     ) : (
                        //         <b>
                        //             {params.row.goal
                        //                 ? +params.row.goal[0].prize
                        //                 : ""}
                        //         </b>
                        //     );
                        // },
                        renderCell: (params) => {
                            if (params.row.bold) return <></>
                            let goal = _.get(params.row, 'goal[0]', {})
                            // if()
                            if (goal.contacts <= goal.contactsValue && goal.tracing <= goal.tracingValue && goal.sale <= goal.saleValue) {
                                return <b>{_.get(params.row, 'goal[0].prize')} <CheckCircleOutline color="success" sx={{ fontSize: 16 }} /></b>
                            }
                            // return Object.keys(params.row.goal[0]).join(', ')
                            return <b>{_.get(params.row, 'goal[0].prize')}</b>
                        }
                    },
                    {
                        field: "sales_plus",
                        width: 100,
                        headerName: "Ventas+",

                        renderCell: (params) => {
                            return (
                                <b>
                                    {params.row.goal
                                        ? params.row.goal[1].saleValue +
                                        "/" +
                                        params.row.goal[1].sale
                                        : "0/0"}
                                </b>
                            );
                        },
                    },
                    {
                        field: "prize_plus",
                        width: 100,
                        headerName: "Premio+",
                        renderCell: (params) => {
                            if (params.row.bold) return <></>
                            let goal = _.get(params.row, 'goal[0]', {})
                            let goalSale = _.get(params.row, 'goal[1]', {})
                            // if()
                            if (goal.contacts <= goal.contactsValue && goal.tracing <= goal.tracingValue && goalSale.sale <= goalSale.saleValue) {
                                return <b>{_.get(params.row, 'goal[1].prize')} <CheckCircleOutline color="success" sx={{ fontSize: 16 }} /></b>
                            }
                            // return Object.keys(params.row.goal[0]).join(', ')
                            return <b>{_.get(params.row, 'goal[1].prize')}</b>
                        },
                    },

                    {
                        field: "a_cobrar",
                        width: 100,
                        headerName: "Obtenido",
                        renderCell: (params) => {
                            if (params.row.bold) return params.row.obtenido ? <b>{params.row.obtenido}</b> : <b>0</b>
                            let goal = _.get(params.row, 'goal[0]', {})
                            let goalSale = _.get(params.row, 'goal[1]', {})

                            let goal_prize = parseInt(_.get(params.row, 'goal[0].prize'), 10)
                            let goal_puls_prize = parseInt(_.get(params.row, 'goal[1].prize'), 10)
                            let goal_plus_sales = parseInt(_.get(params.row, 'goal[1].sale', {}))
                            let goal_plus_sales_value = parseInt(_.get(params.row, 'goal[1].saleValue', {}))
                            // if()
                            if (goal.contacts <= goal.contactsValue && goal.tracing <= goal.tracingValue && goalSale.sale <= goalSale.saleValue) {
                                let isValid = [goal_prize, goal_puls_prize, goal_plus_sales, goal_plus_sales_value].filter((valueINT) => isNaN(valueINT)).length == 0
                                if (!isValid) return <></>
                                return <b>{goal_prize + (goal_puls_prize * (goal_plus_sales_value - goal_plus_sales + 1))}</b>
                            } else if (goal.contacts <= goal.contactsValue && goal.tracing <= goal.tracingValue && goal.sale <= goal.saleValue) {
                                return <b>{goal_prize}</b>
                            }
                            // return Object.keys(params.row.goal[0]).join(', ')
                            return <b>0</b>
                        },
                    },
                ].map((v) => ({
                    ...v,
                    sortable: false,
                    headerClassName: "header-meta",
                }))}
                rows={!resultQuery.data ? [] : getRows(resultQuery.data.list)}
                rowsPerPageOptions={[24]}
                pageSize={24}
                pagination
                autoHeight
                disableColumnMenu={true}
                rowHeight={32}
                headerHeight={32}
                loading={resultQuery.status == "loading"}
            />
        </Box>
    );
};

Goal.defaultProps = {
    seller: true,
};

export default Goal;
