import { summaryGanerate } from "@/components/StatusCard/goalHelpers";
import { Accordion, AccordionContent, AccordionItem } from "@/components/ui/accordion";
import { ChevronDown, User } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { Badge } from "../ui/badge";
import { ItemUserCell } from "./ItemUserCell";
import _ from "lodash";

export default function CellGroupAndUser({ group, handleSelectedUser, selectedSeller, firstGroup, handleSelectedGroup }) {
    const [isOpenCollapse, setOpenCollapse] = useState('')
    const businessName = group.businessName

    useEffect(() => {
        if (firstGroup) setOpenCollapse(group._id)
    }, [firstGroup])

    const sellersSummary = useMemo(() => {
        const summarySellers = summaryGanerate(group, { '#d32f2f': 0, '#ED6C02': 0, '#2e7d32': 0 })
        return summarySellers
    }, [group])

    const orderUsers = useMemo(() => {
        const colorOrder = { "#d32f2f": 1, "#ED6C02": 2, "#2e7d32": 3 };
    
        const usersSorted = group.users
            .filter((user) => _.get(user, "active", true))
            .sort((a, b) => {
                const colorA = colorOrder[a.goal?.color] || 4;
                const colorB = colorOrder[b.goal?.color] || 4;
                return colorA - colorB;
            });
    
        return usersSorted;
    }, [group]);
    
    return (
        <div className="w-full">
            <div className="w-full relative p-0">
                <Accordion type="single" value={isOpenCollapse} collapsible className="w-full bg-white pb-0" defaultValue={firstGroup ? group._id : ""} >
                    <AccordionItem
                        value={group._id}
                        className={`border-none pb-0`}
                    >
                        <tr className="flex justify-center pr-7 cursor-pointer relative py-[10px]  items-center border-b px-4 w-full">
                            <button
                                onClick={() => setOpenCollapse(lastValue => lastValue ? null : group._id)}
                                className={`absolute z-[2] h-full hover:bg-slate-50 transition-[background-color] w-[45px] ${selectedSeller ? 'lg:block hidden' : ''} right-0`}>
                                <div className="w-full h-full flex items-center justify-center">
                                    <ChevronDown className={`h-4 w-4 shrink-0 ${isOpenCollapse ? 'rotate-180' : ''} transition-transform duration-200`} />
                                </div>
                            </button>
                            <td onClick={() => handleSelectedGroup(group)} className="h-7 flex-[2] flex items-center justify-start max-h-7 min-h-7 relative p-0 py-1">
                                <div className="flex items-center justify-start gap-2">
                                    <h4
                                        className="text-[14px] text-start flex items-center gap-[7px] font-semibold"
                                        style={{ color: group.color ?? "#0d0d0d" }}
                                    >
                                        {businessName.length > 20 ? businessName.slice(0, 20).concat("...") : businessName}
                                    </h4>
                                </div>
                            </td>
                            {Object.entries(sellersSummary).map(([color, value]: [string, number]) => (
                                <td onClick={() => handleSelectedGroup(group)} className={`h-6 flex-1   flex items-center max-h-6 min-h-6 relative p-0 py-1 `}>
                                    <Badge style={{ color: color, borderColor: color }} variant={'outline'} className="text-[10px] flex items-center gap-1 py-0  px-2" key={color}>
                                        <User size={13} />
                                        {value}
                                    </Badge>
                                </td>
                            ))}
                        </tr>
                        <AccordionContent className="pb-0" style={{ paddingBottom: 0 }}>
                            <div>
                                {group.group.length > 0 &&
                                    group.group
                                        .filter((subGroup) => _.get(subGroup, "active", true))
                                        .sort((a, b) => a?.businessName?.localeCompare(b.businessName))
                                        .map((subGroup) => (
                                            <CellGroupAndUser
                                                firstGroup={false}
                                                handleSelectedGroup={handleSelectedGroup}
                                                selectedSeller={selectedSeller}
                                                handleSelectedUser={handleSelectedUser}
                                                key={subGroup._id}
                                                group={subGroup}
                                            />
                                        ))}
                            </div>
                            <tbody className="flex w-full flex-col text-base text-neutral-950 py-0">
                                {orderUsers &&
                                    orderUsers
                                        .map((user) => <ItemUserCell
                                            selectedSeller={selectedSeller}
                                            handleSelectedUser={handleSelectedUser}
                                            user={user}
                                            key={user._id} />)}
                            </tbody>
                        </AccordionContent>
                    </AccordionItem>
                </Accordion>
            </div>
        </div>
    );
}