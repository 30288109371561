import Module from '@/components/core/Module';
import { useToggle } from '@/hooks/useToggle';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { defaultTabs, DefaultTabs } from '../constants/charts';
import ChartsByTypeSelected from './charts';
import LoaderCharts from './loader-charts';
import ShowChartsElement from './show-charts-element';
import TabsList from './tabs-list';

export default function DashboardCharts({ user }) {
    const [activeTab, setActiveTab] = useState<string>('');
    const [onceSetter, setOnceSetter] = useState<boolean>(false)
    const [showCharts, toggleShowCharts, setShowCharts] = useToggle<false>(false)

    const isLoadingFolders = useSelector((state: any) => state.config.isLoadingFolders)
    const dashboardCharts = useSelector((state: any) => state.config.dashboardCharts)
    const onChangeTab = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab)
    };

    const onHandleSelectDefaultFolderChart = () => {
        let firstChartUse: string = DefaultTabs.Management;
        if (dashboardCharts.length > 0) {
            let defaultVentiaCharts = _.keyBy([...defaultTabs].map((charts) => ({
                ...charts,
                referenceToDefaultChart: charts.ref,
                _id: charts.ref
            })), 'referenceToDefaultChart');

            let withRef: any[] = [];
            let withoutRef: any[] = [];

            dashboardCharts.forEach((cv) => {
                if (cv.referenceToDefaultChart) {
                    delete defaultVentiaCharts[cv.referenceToDefaultChart];
                    return withRef.push(cv)
                }

                withoutRef.push(cv)
            })

            withRef = [...withRef, ...Object.values(defaultVentiaCharts)];

            const managementChart = withRef.find(({ referenceToDefaultChart }) =>
                referenceToDefaultChart == DefaultTabs.Management
            );

            if (managementChart) {
                firstChartUse = managementChart._id
            }

            if (!managementChart || (managementChart &&
                (managementChart.hidden || (('show' in managementChart) && !managementChart.show)))
            ) {
                let firstDefaultChart = withRef.find(({ show, hidden }) => !hidden && (typeof show !== 'boolean' || show));

                if (!firstDefaultChart) {
                    firstDefaultChart = withoutRef[0];
                }

                if (firstDefaultChart) {
                    firstChartUse = firstDefaultChart?._id || firstDefaultChart?.referenceToDefaultChart;
                } else {
                    firstChartUse = ''
                }
            }

            return firstChartUse
        }
    }

    // Con useEffect no se porque razon no eschucha los cambios de las dependencias en algunos momentos
    const defualtValue = useMemo(() => {
        if (isLoadingFolders || onceSetter) return;
        setOnceSetter(true)
        const value = onHandleSelectDefaultFolderChart()
        onChangeTab(value || 'management');
        return value;
    }, [dashboardCharts, isLoadingFolders])


    if (!showCharts) return (
        <ShowChartsElement
            onChangeShowCharts={setShowCharts}
            toggleShowCharts={toggleShowCharts}
        />
    )

    return (
        <Module
            title={'Gráficas'}
            classNameHeaderContainer="w-full"
            action={(
                <div>
                    <TabsList
                        activeTab={activeTab}
                        onChangeTab={onChangeTab}
                        personalizedChartsOptions={dashboardCharts}
                    />
                </div>
            )}
        >
            {
                isLoadingFolders
                    ? <LoaderCharts />
                    : (
                        <ChartsByTypeSelected
                            dashboardCharts={dashboardCharts || []}
                            activeTab={activeTab}
                            user={user}
                        />
                    )
            }
        </Module>
    )
}

