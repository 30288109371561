import { getCompany } from "@/actions/auth";
import { getStatusTagByCode } from "@/helpers/tagColorToPalette";
import { Chat } from "@mui/icons-material";
import { Chip, IconButton } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import Link from 'next/link';

export default [
    {
        field: "_id",
        headerName: "Chat",
        description: "Abrir chat",
        width: 50,
        renderCell: (params) => {
            if (!params.value || params.value == "") return (<IconButton disabled>
                <Chat />
            </IconButton>)
            return <Link href={`/contact?id=${params.value}`}>
                <IconButton onClick={() => { console.log(params.row) }}>
                    <Chat />
                </IconButton>
            </Link>
        }
    },
    {
        field: "createdAt",
        headerName: "Fecha y hora creado",
        description: "Creado",
        width: 200,
        renderCell: (params) => {
            return moment(new Date((params.value))).format('L LTS')
        }
    },
    {
        field: "firstName",
        headerName: "Nombre contacto",
        description: "Nombre del contacto",
        width: 200,
        renderCell: (params) => {
            return params.value ? params.value : '';
        }
    },
    {
        field: "lastName",
        headerName: "Apellido contacto",
        description: "Apellido del contacto",
        width: 200,
        renderCell: (params) => {
            return params.value ? params.value : '';
        }
    },
    {
        field: "phones",
        headerName: "Teléfono contacto",
        description: "Teléfono",
        width: 200,
        renderCell: (params) => params?.value?.[0] || ""
    },
    {
        field: "emails",
        headerName: "Emails",
        description: "Emails",
        width: 200,
        renderCell: (params) => {
            return params.row.emails ? params.row.emails.join(", ") : ""
        },
    },
    {
        field: 'agent',
        headerName: 'Vendedor',
        description: 'Vendedor',
        width: 150,
        renderCell: (params) => {
            return [params?.value?.name, params?.value?.lastname].filter(Boolean).join(' ')
        }
    },
    {
        field: "status",
        headerName: "Estado",
        description: "Estado",
        width: 150,
        renderCell: (params) => {
            switch (params.value) {
                case "new":
                    return (
                        <Chip color="default" label="Nuevo" size="small" />
                    )
                    break
                case "active":
                    return (
                        <Chip
                            color="primary"
                            label="En seguimiento"
                            size="small"
                        />
                    )
                    break
                case "reminder":
                    return (
                        <Chip
                            color="secondary"
                            label="Recordatorio"
                            size="small"
                        />
                    )
                    break
                case "overdue_reminder":
                    return (
                        <Chip
                            color="error"
                            label="Recordatorio vencido"
                            size="small"
                        />
                    )
                    break
                case "disregarded":
                    return (
                        <Chip
                            color="error"
                            label="Desatendido"
                            size="small"
                        />
                    )
                    break
                case "sold":
                    return (
                        <Chip
                            color="success"
                            label="Vendido"
                            size="small"
                        />
                    )
                    break
                case "closed":
                    return (
                        <Chip
                            color="warning"
                            label="Cerrado"
                            size="small"
                        />
                    )
                    break
            }
            return params.value
        },
    },
    {
        field: "statusTag",
        headerName: "Etiqueta",
        description: "Etiqueta",
        width: 150,
        renderCell: (params) => {
            const statusTag = getStatusTagByCode(params.value);
            return statusTag?.name || "";
        },
    },
    {
        field: "archivingReasonText",
        headerName: "Cierre",
        description: "Cierre",
        width: 150,
        renderCell: (params) => {
            return params.value
                ? params.value
                : params.row.archivingReason || ""
        },
    },
    {
        field: "reminder",
        headerName: "Recordatorio",
        description: "Recordatorio",
        width: 150,
        renderCell: (params) => {
            return params.value ? moment(params.value).calendar() : ""
        },
    },
    {
        field: "sold",
        headerName: "Vendido",
        description: "Vendido",
        width: 150,
        renderCell: (params) => {
            return (!params.value && params.row.status !== 'sold')
                ? 'No'
                : 'Si'
        },
    },
    {
        field: "lastInteraction",
        headerName: "Última interacción",
        description: "Última interacción",
        width: 250,
        renderCell: (params) => {
            return params.value ? moment(params.value).calendar() : ""
        },
    },
    {
        field: "detail",
        headerName: "Detalle",
        description: "Detalle",
        width: 250,
    },
    {
        field: "visits_count",
        headerName: "Visitas",
        description: "Visitas",
        width: 150,
        renderCell: (params) => {
            return _.get(params.row, "stats.visits", "Sin visitas")
        },
    },
    {
        field: "lastVisit_date",
        headerName: "Última visita",
        description: "Última visita",
        width: 150,
        renderCell: (params) => {
            let value = _.get(
                params.row,
                "stats.lastVisit",
                "Sin visita"
            )
            return value == "Sin visita"
                ? value
                : moment(value).format("L") +
                " " +
                moment(value).format("LTS")
        },
    },
    ..._.get(getCompany(), "additionalDataFields", [])
        .filter((field) => field.active)
        .map((field) => ({
            field: "additionaData_" + field.code,
            headerName: field.name,
            description: field.name,
            width: 200,
            renderCell: (params) => {
                const data = _.get(
                    params.row,
                    "additionalData." + field.code,
                    ""
                )
                if (field.type == 'multiselect') {
                    return data ? [data].flat().join(', ') : ''
                }
                return data
            },
        })),
];