import { IGoalState, STATES_GOAL, fieldColors } from "@/components/StatusCard/interface";
import _ from "lodash";
import moment from "moment";

export const calculateGoal = (currentGoal, goalToModify): IGoalState => {
    const goalToday = Math.round((currentGoal.expected / goalToModify.daysGoal) * goalToModify.days);
    const percentage = Math.round((100 / goalToday) * currentGoal.value);
    let state = '';

    if (percentage >= 100) state = STATES_GOAL.UPTODATE
    if (percentage < 100) state = STATES_GOAL.BAD
    if (percentage < 50) state = STATES_GOAL.VERYBAD
    const isCompleteGoal = currentGoal.expected <= currentGoal.value

    return {
        state,
        complete: isCompleteGoal,
        expected: goalToday,
        color: fieldColors[state]?.color ?? ''
    };
}

export const calculateBusinessDays = (startDate, endDate) => {
    let days = 0;
    for (let dateToAdd = moment(startDate).startOf('day'); dateToAdd.diff(endDate, 'day') <= 0; dateToAdd.add(1, 'days')) {
        if (dateToAdd.weekday() !== 5 && dateToAdd.weekday() !== 6) {
            days++;
        }
    }
    return days;
};

export const getGroupList = (group, getAllDataFromSellers) => {
    const groupFind = getAllDataFromSellers.filter((groupToFilter) => {
        const { ancestors } = groupToFilter;
        const ancestorsLength = ancestors.length - 1;
        return (
            ancestorsLength >= 0 &&
            ancestors[ancestorsLength] === group._id &&
            _.get(groupToFilter, "active", true)
        );
    });

    const groups = groupFind.map((subGroup) => getGroupList(subGroup, getAllDataFromSellers));
    return { ...group, group: groups };
};

export const getColorCall = (times: string): string => {
    if (!times) return "";
    const currentTime = new Date();
    currentTime.setHours(0, 0, 0, 0);
    const callDate = new Date(times);
    
    return currentTime < callDate
        ? "#28a745"
        : currentTime < callDate
            ? "#ffc107"
            : "#dc3545";
}

export const summaryGanerate = (group, summary) => {
    group.users.forEach((user) => {
        const colorUser = user?.goal?.color;
        if (colorUser && summary[colorUser] !== undefined) {
            summary[colorUser] = summary[colorUser] + 1;
        }
    });
    if (group.group.length > 0) group.group.forEach(group => summaryGanerate(group, summary))
    return summary
}

export const getAllGoalsInformation = (group, listUsersToSearch, addColorsGoal) => {
    const dataGroup = _.cloneDeep(group)

    const users = dataGroup.users.filter((user) => user.active && user.roles.includes("user")).map(user => {
        const findAllGoals = addColorsGoal.filter(goal => goal.user._id === user._id);
        return { goal: findAllGoals[0] ?? [], ...user };
    });

    listUsersToSearch.push(users);
    return { ...group, users };
}

export const getAllGroups = (allData: Array<any> = []) => {
    let minLevel: number;

    allData.map((group) => {
        const ancestorsLength = group.ancestors.length;
        if (minLevel === undefined || minLevel > ancestorsLength) {
            minLevel = ancestorsLength;
        }
    });

    const newGroupStruct = allData
        .filter((group) => group.ancestors.length === minLevel)
        .map((group) => getGroupList(group, allData));

    return newGroupStruct;
}

export const updateGoalInformation = (goalToModify, infoGoalToModify, selection) => {
    const startDate = moment(goalToModify.start).startOf('day');
    const endDate = moment(goalToModify.end).startOf('day');
    goalToModify.daysGoal = calculateBusinessDays(startDate, endDate);
    let now = moment().startOf('day');

    if (selection === 'yesterday') {
        now = now.add(-1, 'days');
    }

    const daysUntilToday = calculateBusinessDays(startDate, now);
    const totalDays = goalToModify.daysGoal > 0 ? goalToModify.daysGoal : 1;

    infoGoalToModify.days = `${daysUntilToday}/${totalDays}`;
    goalToModify.days = daysUntilToday === 0 ? 1 : daysUntilToday;
}
