import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Command, CommandGroup, CommandItem, CommandList } from "@/components/ui/command";
import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import useStatusCard from "@/hooks/useStatusCard";
import '@/styles/scrollModify.css';
import { ListFilter, Search } from "lucide-react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setUsersGroup } from "../../redux/actions/main";
import LoadingModule from "../loadings/LoadingModule";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { Table, TableBody, TableCell } from "../ui/table";
import CellGroupAndUser from "./CellGroupAndUser";
import GraphicsArea from "./GraphicsArea";
import { ItemUserCell } from "./ItemUserCell";
import NoGoalsCard from "./NoGoalsCard";
import StatusCardInformation from "./StatusCardInformation";
import { columnsTable } from "./columnsTable";
import { IGoalInfo, colorsFilter } from "./interface";

export const defaultvaluesInfoGoals: IGoalInfo = { lates: 0, veryLates: 0, upToDate: 0, complete: 0, color: "", days: "" }

const StatusCard = ({ groupFilter, user, usersGroup }) => {
    const [selectedSeller, setSelectedSeller] = useState<any>(null)
    const [openDialogSellers, setOpenDialogSellers] = useState<boolean>(false);
    const {
        keywordSearch, selection, allSubGroups, dateGoal,
        goalInformation, resultToSearchSeller, setFilter,
        setKeywordSearch, setSelection, filter, isLoading
    } = useStatusCard({ groupFilter, user, usersGroup })

    useEffect(() => { setSelectedSeller(null) }, [openDialogSellers])

    const handleSelectedGroup = (group) => {
        setSelectedSeller(group)
    }

    if (isLoading || !allSubGroups) return <LayoutCard>
        <div className="h-[80px] w-full py-2 lg:py-5  flex items-center justify-center">
            <LoadingModule />
        </div>
    </LayoutCard>

    return (
        <>
            <LayoutCard>
                <Card
                    className={`rounded-none w-full border-none shadow-none ${allSubGroups.length > 0 ? 'cursor-pointer' : ''}`}
                    onClick={() => allSubGroups.length > 0 ? setOpenDialogSellers(true) : null}
                >
                    <CardContent className="p-0">
                        <CardHeader className="flex flex-row items-center justify-between py-2 lg:py-5">
                            {
                                allSubGroups.length > 0
                                    ? <StatusCardInformation goalInformation={goalInformation} />
                                    : <NoGoalsCard />
                            }
                        </CardHeader>
                    </CardContent>
                </Card>
            </LayoutCard>
            {/* -------------Start Dialog------------- */}
            <Dialog open={!!openDialogSellers} onOpenChange={setOpenDialogSellers}>
                <DialogContent
                    className="sm:max-w-[1070px] relative lg:max-h-[90vh] overflow-hidden lg:min-h-[90vh] min-h-[100vh] p-0  m-0"
                    style={{ paddingBottom: 0, margin: 0 }}
                >
                    <div className="w-full flex sm:pl-6 pl-2 max-h-[100%] relative items-center">
                        <div className={`h-full flex-[4] py-4 ${selectedSeller ? 'lg:flex hidden' : 'flex'}  flex-col gap-2`}>
                            <div className="w-full  flex flex-col ">
                                <DialogTitle className="text-[17px]">Rendimiento de vendedores</DialogTitle>
                                <div className="w-full flex items-center mt-1 justify-end">
                                    <FilterContainer
                                        filter={filter}
                                        keywordSearch={keywordSearch}
                                        selection={selection}
                                        setFilter={setFilter}
                                        setKeywordSearch={setKeywordSearch}
                                        setSelection={setSelection} />
                                </div>
                            </div>
                            <div className="relative flex-grow">
                                {/* -------------Start Table------------- */}
                                <div className="w-full overflow-hidden h-full mb-[5px] min-h-[60vh] box-border relative mt-2">
                                    <div className="relative overflow-auto h-full modifyScrollChatCrm">
                                        <Table className="absolute left-0 h-full w-full">
                                            <thead className="w-full">
                                                <tr className="flex-1 border-b px-3 w-full flex items-center">
                                                    {columnsTable.map((header, inx) =>
                                                        header.isPlaceholder ? null : (
                                                            <td key={header.accessorKey} className={` h-12 flex items-center text-left align-middle ${inx == 0 ? 'flex-[2]' : 'flex-1'} font-medium text-muted-foreground`}>
                                                                {header.header}
                                                            </td>
                                                        )
                                                    )}
                                                </tr>
                                            </thead>
                                            <TableBody className="w-full absolute">
                                                <div className="w-full">
                                                    {keywordSearch.length > 0 || filter
                                                        ? resultToSearchSeller.length === 0
                                                            ? <TableCell className="h-20 text-center w-full">
                                                                Sin resultados
                                                            </TableCell>
                                                            : resultToSearchSeller.map(user => (
                                                                <ItemUserCell selectedSeller={selectedSeller} handleSelectedUser={(user) => setSelectedSeller(user)} user={user} key={user._id} />
                                                            ))
                                                        : allSubGroups.length > 0 && (
                                                            <>
                                                                <CellGroupAndUser
                                                                    handleSelectedGroup={handleSelectedGroup}
                                                                    selectedSeller={selectedSeller}
                                                                    handleSelectedUser={(user) => setSelectedSeller(user)}
                                                                    group={allSubGroups[0]}
                                                                    firstGroup={true}
                                                                    key={allSubGroups[0]._id}
                                                                />
                                                            </>
                                                        )}
                                                </div>
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                                {/* -------------End Table------------- */}
                            </div>
                        </div>
                        <GraphicsArea
                            isGroupSelected={selectedSeller && ('users' in selectedSeller)}
                            onCLoseModal={() => setOpenDialogSellers(false)}
                            selectedSeller={selectedSeller && { ...selectedSeller, goalPeriod: dateGoal }}
                            handleDeleteSeller={() => setSelectedSeller(null)} />
                    </div>
                </DialogContent>
            </Dialog>
            {/* -------------End Dialog------------- */}
        </>
    );
};

export function FilterContainer({ setSelection, selection, setKeywordSearch, keywordSearch, filter, setFilter }) {
    return <div className="flex relative gap-1 pr-2 px-8">
        <Select onValueChange={(value: "today") => setSelection(value)} value={selection}>
            <SelectTrigger className="w-[100px]">
                <SelectValue placeholder="Hoy" />
            </SelectTrigger>
            <SelectContent>
                {[['today', 'Hoy'], ['yesterday', 'Ayer']].map(([value, label]) => (
                    <SelectItem value={value} key={value}>{label}</SelectItem>
                ))}
            </SelectContent>
        </Select>
        <form onSubmit={(e) => e.preventDefault()} className="h-[40px] py-[2px] m-0 flex items-center border border-input rounded-md">
            <Input
                autoComplete="off"
                placeholder="Buscar por vendedor..."
                onChange={(e) => setKeywordSearch(e.target.value)}
                value={keywordSearch}
                autoFocus
                className="border-none placeholder:text-xs focus-visible:ring-0 focus-visible:ring-offset-0"
            />
            <button className='text-primary mr-3' aria-label="search">
                <Search size={15} />
            </button>
        </form>
        <Popover>
            <PopoverTrigger asChild>
                <Button variant={'outline'} className="relative w-[40px] h-[40px]">
                    {filter && <span className="w-[8px] h-[8px] rounded-full -top-[3px] -right-[3px] absolute bg-primary"></span>}
                    <span>
                        <ListFilter size={17} />
                    </span>
                </Button>
            </PopoverTrigger>
            <PopoverContent className="flex flex-col p-0 pt-2">
                <div className="px-4 relative my-[5px]">
                    <h4 className="font-semibold text-sm leading-none">Filtrado por color</h4>
                </div>
                <div className="flex flex-col gap-2">
                    <Command>
                        <CommandList className="h-max relative gap-1 flex flex-col p-0">
                            <CommandGroup>
                                {
                                    colorsFilter.map(color => {
                                        const colorToUse: string = color.color
                                        return <div onClick={() => setFilter(filter === colorToUse ? '' : colorToUse)} key={colorToUse}>
                                            <CommandItem className={`cursor-pointer px-4 space-x-2 ${filter === colorToUse ? 'bg-accent' : ''}`} >
                                                <div className={`w-[15px] h-[15px] rounded-full border-2 border-transparent shrink-0`}
                                                    style={{ backgroundColor: colorToUse }}>
                                                </div>
                                                <span className="text-[15px]">{color.label}</span>
                                            </CommandItem>
                                        </div>
                                    })
                                }
                            </CommandGroup>
                        </CommandList>
                    </Command>
                </div>
            </PopoverContent>
        </Popover>
    </div>
}

export function LayoutCard({ children }) {
    return (
        <div className="w-[100%] overflow-hidden relative top-0 left-0">
            <div className={'sticky left-0 bg-white right-0 m-auto top-0 z-[3] border-b border-b-neutral-200 w-[100%]'}>
                {children}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    usersGroup: state.main.usersGroup,
});

const mapDispatchToProps = {
    setUsersGroup: setUsersGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusCard);