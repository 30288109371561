import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import _ from "lodash";



interface ToggleButtonTextInterface<T> {
    options: {icon: JSX.Element, text: string, value: T, activeOn?: T[],}[],
    value: string,
    onChange: (value: T) => void,

}



const ToggleButtonText = ({options, value, onChange}:ToggleButtonTextInterface<string>) => {


    return (
        <ToggleButtonGroup
                        value={value}
                        exclusive
                        sx={{ mt: 0 }}
                    >
                {options.map((option,inx) => (
                    <ToggleButton
                        value={value}
                        key={inx}
                        onClick={() => onChange(option.value)}
                        sx={{
                            py: 0.25,
                            "&:hover": {
                                color: "white !important",
                                borderColor: "#0080ff !important",
                                background: "#0080ff !important",
                            },
                            color: (value == option.value ||  _.get(option, 'activeOn', []).includes(value))
                                ? "white !important"
                                : "#0080ff !important",
                            borderColor: (value == option.value ||  _.get(option, 'activeOn', []).includes(value))
                                ? "#0080ff !important"
                                : "#0080ff !important",
                            background: (value == option.value ||  _.get(option, 'activeOn', []).includes(value))
                                ? "#0080ff !important"
                                : "#fff !important",
                        }}
                        
                    >
                        {option.icon}
                        <Typography ml={0.5} fontSize={14} sx={{my: 0.5}}>
                            {option.text}
                        </Typography>
                    </ToggleButton>
            ))}
        </ToggleButtonGroup>
    )
}


export default ToggleButtonText