import { AttachMoney, BarChart, FilterAlt, Numbers, People, Percent, Person, Today } from "@mui/icons-material";
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { getCompany, isAuth } from "../../actions/auth";
import ToggleButtonText from "../core/ToggleButtonText";
import FilterDate from "../reports/FilterDate";
import FilterMultiSelect from "../reports/FilterMultiSelect";
import UserFilterAvanced from "../reports/UserFilterAvanced";
import useUserFilterAvanced from "../reports/UserFilterAvanced/useUserFilterAvanced";
import Graph, { GraphInterface } from "./Graph";


interface GraphItem {
    title: string;
    graphs: GraphInterface[];
    useAsUser?: any;
    dateFilter?: {};
    defaultGroupType?:
    | "date"
    | "week"
    | "all"
    | "monthyear"
    | "year"
    | "total"
    | "user"
    | "group";
    initialDateFilter?: string;
    disablePDFExport?: boolean;
    initialDateValue?: {
        lt?: string;
        gt?: string;
    };
    filters?: {
        title: string;
        options: [key: string, text: string][];
        keysModel: { [key: string]: string };
        key: string;
    }[];
}

const getDefaultFilterModel = (list) => {
    let result = {};
    if (list) {
        list.map((l) => {
            result[l.key] = ["all"];
        });
    }

    return result;
};

export default function GraphPanel({
    title,
    graphs = [],
    useAsUser,
    defaultGroupType,
    initialDateFilter,
    initialDateValue,
    filters,
}: GraphItem) {
    const [groupType, setGroupType] = useState(defaultGroupType || "date");
    const [dateFilter, setDateFilter] = useState<any>(
        initialDateValue || {
            gt: moment().add(-6, "day").startOf("day").toISOString(),
        }
    );
    const [showFilter, setShowFilter] = useState(true);
    const [actualUser, setActualUser] = useState(isAuth());
    const [showAsNumber, setShowAsNumber] = useState(true);
    const [showAsValue, setShowAsValue] = useState(getCompany()?.groupType != 'facturacion');
    const [updateVersion, setUpdateVersion] = useState(0);
    const [extraFilters, setExtraFilters] = useState(getDefaultFilterModel(filters));
    const hookUserFilterAvanced = useUserFilterAvanced({ useAsUserInit: useAsUser });

    const [
        { activeGroups, usersFilter, activeUsers, allUsers },
        { setUseAsUser },
    ] = hookUserFilterAvanced;

    useEffect(() => {
        setUseAsUser(useAsUser);
    }, [useAsUser]);

    const handleExtraFilters = (key: string) => (change: any) => {
        setExtraFilters({ ...extraFilters, [key]: change });
    };
    type key_array = { [key: string]: string[] };

    const reduceAndParceFilters = (data: key_array, source: string) => {
        let result: key_array = {};

        Object.keys(data).map((key) => {
            if (data[key].length != 0 && data[key][0] != "all") {
                filters.map((f) => {
                    if (f.key == key && f.keysModel[source]) {

                        result[f.keysModel[source]] = data[key];
                    }
                });
            }
        });
        return result;
    };

    let handleGroupChange = (name) => {
        switch (name) {
            case "date":
                setDateFilter({
                    gt: moment().add(-6, "day").startOf("day").toISOString(),
                });
                break;
            case "week":
                setDateFilter({
                    gt: moment().add(-29, "day").startOf("day").toISOString(),
                });
                break;
            case "all":
                setDateFilter({
                    gt: moment().startOf("month").startOf("day").toISOString(),
                });
                break;

            case "monthyear":
                setDateFilter({
                    gt: moment()
                        .add(-2, "month")
                        .startOf("month")
                        .startOf("day")
                        .toISOString(),
                });
                break;
            case "year":
                setDateFilter({
                    gt: moment()
                        .add(-2, "year")
                        .startOf("year")
                        .startOf("day")
                        .toISOString(),
                });
                break;
            case "total":
                break;
        }
        setGroupType(name);
    };

    let translateFromDate = (type: string): any => {
        const map: Record<string, string> = {
            'date': 'day',
            'week': 'week',
            'monthyear': 'month',
            'year': 'year'
        };

        return map[type] || 'all';
    };

    const toggleFilter = () => setShowFilter(!showFilter);

    return (
        <>

            {
                (useAsUser?.roles ? (useAsUser.roles.includes("comercial") || useAsUser.roles.includes("super")) :
                    (actualUser.roles && (actualUser.roles.includes("comercial") || actualUser.roles.includes("super")))) ? (
                    <>
                        <Grid item mb={0.25}>
                            <Typography fontSize={14} color="#666666">Agrupación por</Typography>
                        </Grid>
                        <Grid item alignSelf={"center"} mb={0.75}>
                            <ToggleButtonText
                                options={[
                                    { icon: <Today sx={{ fontSize: 20 }} />, text: "Fecha", value: 'date', activeOn: ['date', 'week', 'month', 'monthyear', 'year', 'total'] },
                                    { icon: <Person sx={{ fontSize: 20 }} />, text: "Vendedor", value: 'user' },
                                    { icon: <People sx={{ fontSize: 20 }} />, text: "Grupo", value: 'group' },
                                ]}
                                value={groupType}
                                onChange={(value: any) => setGroupType(value)}

                            />

                        </Grid>

                    </>
                ) : <></>
            }
            <Grid
                sx={{
                    "@media (max-width: 479px)": { display: "block" },
                    display: "none"
                }}
            >
                <Button
                    variant="outlined"
                    sx={{ mb: 0.75, textTransform: "none" }}
                    onClick={() => toggleFilter()}
                    endIcon={<FilterAlt />}
                >
                    {showFilter ? "Ocultar filtros" : "Mostrar filtros"}
                </Button>
            </Grid>
            <Grid item>
                <Typography fontSize={14} color="#666666">Filtros</Typography>
            </Grid>
            <Box
                sx={{
                    "@media (max-width: 479px)": {
                        display: showFilter ? "flex" : "none",
                        flexDirection: "column",
                    },
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                }}
            >

                {['date', 'week', 'month', 'monthyear', 'year', 'total'].includes(groupType) ?
                    <Grid item>
                        <FormControl
                            sx={{ width: "200px", mr: 2, my: 1 }}
                            size="small"
                        >
                            <InputLabel id="simple-select">
                                Período de fecha
                            </InputLabel>
                            <Select
                                labelId="simple-select"
                                id="form-simple-select"
                                value={groupType}
                                label="Período de fecha"
                                size="small"
                                color="primary"
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 48 * 4.5 + 8,
                                            width: 250,
                                        },
                                    },
                                }}
                                onChange={(e) => handleGroupChange(e.target.value)}
                            >
                                <MenuItem value={"date"}>Diaria</MenuItem>
                                <MenuItem value={"week"}>Semanal</MenuItem>
                                <MenuItem value={"monthyear"}>Mensual</MenuItem>
                                <MenuItem value={"year"}>Anual</MenuItem>

                                <MenuItem value={"total"}>
                                    Periodo seleccionado
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    : <></>}
                <Grid item>
                    <FilterDate
                        title="Fecha"
                        range={translateFromDate(groupType)}
                        initialValue={initialDateFilter || "last_7_days"}
                        setValue={setDateFilter}
                        reloadOnRangeChange={false}
                    />
                </Grid>

                <UserFilterAvanced hook={hookUserFilterAvanced} />
                {filters &&
                    filters.map((f) => (
                        <Grid item>
                            <FilterMultiSelect
                                title={f.title}
                                list={f.options.map((o) => ({
                                    title: o[1],
                                    key: o[0],
                                }))}
                                idKey="key"
                                getName={(g) => {
                                    return g ? g.title : "Sin definir";
                                }}
                                setValue={handleExtraFilters(f.key)}
                                value={extraFilters[f.key]}
                            />
                        </Grid>
                    ))}
                <Grid item alignSelf={"center"}>
                    <ToggleButtonGroup
                        value={showAsNumber ? "si" : "no"}
                        exclusive
                        sx={{ mt: 0.75 }}
                    >
                        <ToggleButton
                            value={"si"}
                            onClick={() => {
                                setShowAsNumber(true);
                                setUpdateVersion(updateVersion + 1);
                            }}
                            sx={{
                                py: 0.25,
                                "&:hover": {
                                    color: "white !important",
                                    borderColor: "#2b7cd2 !important",
                                    background: "#2b7cd2 !important",
                                },
                                color: !showAsNumber
                                    ? "#0080ff !important"
                                    : "white !important",
                                borderColor: !showAsNumber
                                    ? "#0080ff !important"
                                    : "#0080ff !important",
                                background: !showAsNumber
                                    ? "#fff !important"
                                    : "#0080ff !important",
                            }}
                        >
                            <BarChart />
                        </ToggleButton>
                        <ToggleButton
                            value={"no"}
                            onClick={() => {
                                setShowAsNumber(false);
                                setUpdateVersion(updateVersion + 1);
                            }}
                            sx={{
                                py: 0.25,
                                "&:hover": {
                                    color: "white !important",
                                    borderColor: "#2b7cd2 !important",
                                    background: "#2b7cd2 !important",
                                },
                                color: showAsNumber
                                    ? "#0080ff !important"
                                    : "white !important",
                                borderColor: showAsNumber
                                    ? "#0080ff !important"
                                    : "#0080ff !important",
                                background: showAsNumber
                                    ? "#fff !important"
                                    : "#0080ff !important",
                            }}
                        >
                            <Percent />
                        </ToggleButton>
                    </ToggleButtonGroup>
                    {getCompany()?.groupType == 'facturacion' ? (
                        <ToggleButtonGroup
                            value={showAsValue ? "si" : "no"}
                            exclusive
                            sx={{ mt: 0.75, ml: 2 }}
                        >
                            <ToggleButton
                                value={"si"}
                                onClick={() => {
                                    setShowAsValue(true);
                                    setUpdateVersion(updateVersion + 1);
                                }}
                                sx={{
                                    py: 0.25,
                                    "&:hover": {
                                        color: "white !important",
                                        borderColor: "#2b7cd2 !important",
                                        background: "#2b7cd2 !important",
                                    },
                                    color: !showAsValue
                                        ? "#0080ff !important"
                                        : "white !important",
                                    borderColor: !showAsValue
                                        ? "#0080ff !important"
                                        : "#0080ff !important",
                                    background: !showAsValue
                                        ? "#fff !important"
                                        : "#0080ff !important",
                                }}
                            >
                                <Numbers />
                            </ToggleButton>
                            <ToggleButton
                                value={"no"}
                                onClick={() => {
                                    setShowAsValue(false);
                                    setUpdateVersion(updateVersion + 1);
                                }}
                                sx={{
                                    py: 0.25,
                                    "&:hover": {
                                        color: "white !important",
                                        borderColor: "#2b7cd2 !important",
                                        background: "#2b7cd2 !important",
                                    },
                                    color: showAsValue
                                        ? "#0080ff !important"
                                        : "white !important",
                                    borderColor: showAsValue
                                        ? "#0080ff !important"
                                        : "#0080ff !important",
                                    background: showAsValue
                                        ? "#fff !important"
                                        : "#0080ff !important",
                                }}
                            >
                                <AttachMoney />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    ) : <></>}
                </Grid>
            </Box>
            <Grid container direction={"column"} id={title + "-graph-report"}>
                <Grid container item direction={"row"} spacing={2}>
                    {graphs.map((g, index) => {
                        let params: GraphInterface = { group: groupType } as GraphInterface;

                        Object.keys(g).map((name) => {
                            if (g[name] != "default") params[name] = g[name];
                        });

                        return (
                            <Grid
                                item
                                xs={12}
                                md={groupType == "user" ? 12 : 6}
                                key={`${index}-${params.title}-${params.source}`}
                            >
                                <Graph
                                    {...params}
                                    groupTitle={title}
                                    datefilter={dateFilter}
                                    userfilter={usersFilter}
                                    testing={false}
                                    percentage={!showAsNumber}
                                    valueOption={!showAsValue}
                                    updateVersion={updateVersion}
                                    activeUsers={allUsers}
                                    groupsUse={activeGroups}
                                    match={{
                                        ...params.match,
                                        ...reduceAndParceFilters(
                                            extraFilters,
                                            params.source
                                        ),
                                    }}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </>
    );
}
