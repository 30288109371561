import { AttachMoney, EmojiEvents, Group, SpatialTracking } from "@mui/icons-material"
import _ from "lodash"
import { ArrowRight, Clock, Inbox } from "lucide-react"
import { useRouter } from "next/router"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { isAuth } from "../../actions/auth"
import { getDailyStats } from "../../actions/comercial/metas"
import { STATUS_COLORS } from "../StatusCard/interface"
import Module from "../core/Module"
import LoadingModule from "../loadings/LoadingModule"
import { Avatar } from "../ui/avatar"
import { Badge } from "../ui/badge"
import { Button } from "../ui/button"
import { Card, CardDescription, CardTitle } from "../ui/card"
import { Separator } from "../ui/separator"


interface TrophyInterface {
    user: any
    currentUser: any
    userSelected: any
}

interface ItemTrophy {
    icon: any,
    title: string,
    header: string,
    chip?: any
}

const ItemTrophy = ({ icon, title, header, chip }: ItemTrophy) => {
    return <Card className="flex items-center justify-between gap-3 shadow-none shrink-0 border-none bg-[#EFF6FF] py-2 px-5 lg:px-5 w-full md:w-auto">
        <div className="flex gap-2">
            {icon}
            <div>
                <div>
                    <CardDescription className="text-primary font-medium">
                        {header}
                    </CardDescription>
                </div>
                {chip ? chip : <></>}
            </div>
        </div>
        <Separator orientation="vertical" className="h-[80%] bg-primary" />
        <div className="rounded bg-primary p-1 h-6 min-w-6 flex items-center justify-center">
            <CardTitle className="text-[12px] text-white">
                {title}
            </CardTitle>
        </div>
    </Card>
}

const Trophy = ({ user, userSelected, currentUser }: TrophyInterface) => {
    const [isScrolled, setIsScrolled] = useState(false);

    let userToUse = user
    const router = useRouter()
    if (!user) {
        userToUse = _.get(isAuth(), '_id', '') as string
    }

    let statsQuery = useQuery({
        queryFn: () => getDailyStats(userToUse),
        queryKey: ['user-stats-query', userToUse]
    })

    let data = _.get(statsQuery, 'data.data', {})

    const statusTexts = {
        [STATUS_COLORS['VERYBAD']]: 'Muy atrasado',
        [STATUS_COLORS['BAD']]: 'Atrasado',
        [STATUS_COLORS['UPTODATE']]: 'Objetivo al día'
    }

    const handleRedirectToInbox = () => {
        if (userSelected) {
            return router.push(`/contact?inbox=${userSelected?._id}`)
        }
    }

    const showAsSupervisor = useMemo(() => {
        return currentUser && userSelected && currentUser?._id !== userSelected?._id
            && currentUser?.roles && (currentUser?.roles?.includes('comercial') || currentUser?.roles.includes('super'))
    }, [userSelected, currentUser])

    const checkScroll = useCallback(() => {
        const scrollThreshold = 100.05;
        const isScrolled = window.scrollY >= scrollThreshold;
        setIsScrolled(isScrolled);
    }, [setIsScrolled]);

    useEffect(() => {
        window.addEventListener('scroll', checkScroll);
        return () => {
            window.removeEventListener('scroll', checkScroll);
        };
    }, [checkScroll]);

    if (statsQuery.isFetching || statsQuery.error || statsQuery.data?.data?.error) return <Card className="w-full shadow-none border-none py-7 rounded-none bg-white" >
        <LoadingModule />
    </Card>

    return <div className="bg-white p-5 border-b border-neutral-200">
        {
            showAsSupervisor ? <div className={`flex items-center  shrink-0 px-0 ${isScrolled ? '' : 'border-b-neutral-100 border-b pb-4'} justify-between  w-full`}>
                <div className="flex w-full flex-col md:flex-row h-full gap-3 items-start md:items-center border-b-neutral-50 justify-start ">
                    <CardTitle className="text-[12px] md:text-[16px]">
                        Resumen del vendedor
                    </CardTitle>
                    <Separator orientation="vertical" className="h-[50%] hidden md:flex" />
                    <div className="flex gap-2">
                        <div className="flex gap-1 md:gap-2 items-center shrink-0">
                            <Avatar className='bg-primary text-white font-medium flex items-center justify-center w-5 h-5 md:w-[25px] md:h-[25px] border-primary text-xl border-2'>
                                {userSelected?.photo ? <img src={userSelected?.photo} alt="" /> :
                                    <span className="text-[10px] md:text-[12px] text-white">
                                        {userSelected?.name?.charAt(0)}{userSelected?.lastname?.charAt(0)}
                                    </span>
                                }
                            </Avatar>
                            <CardDescription className="text-[12px] md:text-[14px] font-medium">
                                {userSelected?.name} {userSelected?.lastname}
                            </CardDescription>
                        </div>
                        <div className="flex items-center gap-1 shrink-0" style={{ color: userSelected.color }}>
                            <Clock size={12} />
                            <p className="text-white rounded-sm text-[12px]" style={{ color: userSelected.color }}>
                                {statusTexts[userSelected.color] ?? <span className="text-primary">Sin objetivos</span>}
                            </p>
                        </div>
                    </div>
                </div>
                <Button
                    onClick={handleRedirectToInbox}
                    variant={'link'}
                    className="text-black text-[13px] truncate hover:text-primary p-0 items-center h-auto gap-1">
                    <Inbox size={18} />
                    Ver bandeja
                    <ArrowRight size={16} />
                </Button>
            </div> : (
                <div className=" flex items-center py-1 pb-2 justify-between border-b-[#ecf3fa] border-b">
                    <CardTitle className="text-[16px]">
                        Rendimiento
                    </CardTitle>
                </div>
            )
        }
        {
            (!isScrolled || !showAsSupervisor) && <div className="flex flex-col mt-4 gap-2 md:flex-row sm:grid-cols-1 xl:gap-8 lg:justify-center justify-center items-center ">
                <ItemTrophy
                    icon={<SpatialTracking sx={{ color: "#0080FF", fontSize: 20 }} />}
                    title={`${data.tracings}`}
                    header={"Seguimientos de hoy"}
                />
                {!data.usesGoals ?
                    <ItemTrophy
                        icon={<Group sx={{ color: "#0080FF", fontSize: 20 }} />}
                        title={`${data.contacts}`}
                        header={"Contactos de hoy"}
                    />
                    : data.prizeWon != 0 || data.prizeWonBefore == 0 ?
                        <ItemTrophy
                            icon={<EmojiEvents sx={{ color: "#0080FF", fontSize: 20 }} />}
                            title={`$${data.prizeWon}`}
                            header={"Ganado en el mes"}
                        />
                        :
                        <ItemTrophy
                            icon={<EmojiEvents sx={{ color: "#0080FF", fontSize: 20 }} />}
                            title={`$${data.prizeWonBefore}`}
                            header={"Ganado mes pasado"}
                        />
                }
                <ItemTrophy
                    icon={<AttachMoney sx={{ color: "#0080FF", fontSize: 20 }} />}
                    title={data.sales}
                    header={"Ventas de este mes"}
                />
            </div>
        }

    </div>
}


export default Trophy