import { ITab } from "../types";

export const DefaultTabs = {
    Management: 'management',
    Sales: 'sales',
    Closures: 'closures',
    Clients: 'clients'
} as const

const { Clients, Closures, Management, Sales } = DefaultTabs

export const defaultTabs: ITab[] = [
    {
        value: Management,
        label: 'Gestión',
        ref: 'management'
    },
    {
        value: Sales,
        label: 'Ventas',
        ref: 'sales'
    },
    {
        value: Closures,
        label: 'Cierres',
        ref: 'closures'
    },
    {
        value: Clients,
        label: 'Clientes',
        ref: 'clients'
    }
]

export type DefaultTabsType = typeof DefaultTabs[keyof typeof DefaultTabs]