import { CardDescription, CardTitle } from "@/components/ui/card";
import { BarChart3 } from "lucide-react";
import Link from "next/link";

export default function NoGoalsCard() {
    return <div className="flex gap-2">
        <div className="text-primary">
            <BarChart3 size={25} />
        </div>
        <div className="flex flex-col gap-1">
            <div className="flex items-center gap-2">
                <CardTitle
                    className="text-[17px] space-x-2 flex items-center">
                    No tienes metas subidas
                </CardTitle>
            </div>
            <CardDescription>
                Sube las metas dando{" "}
                <Link className="text-primary" href="https://panel.getventia.com/goals/upload"
                >
                    click aquí
                </Link>
            </CardDescription>
        </div>
    </div>
}
