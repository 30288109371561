import { fetcher } from "@/helpers/fetcher"
import { formatSearchParams } from "@/helpers/formatSearchParams"

export const getCalendarEvents = (filters: Record<string, any> = {}) => {
    const params = formatSearchParams(filters)
    return fetcher<{ success: boolean, events: Array<any> }>({
        url: `/calendar/events?${params}`,
        defaultContentType: true
    })
}

export const getMeetingsList = (filters: Record<string, any> = {}) => {
    const params = formatSearchParams(filters)
    return fetcher<{ success: boolean, meetings: Array<any> }>({
        url: `/calendar/meetings?${params}`,
        defaultContentType: true
    })
}