import moment from 'moment';
import { getStatusTagByCode } from "../../../helpers/tagColorToPalette";

export default [
    {
        field: 'createdAt',
        headerName: 'Fecha',
        description: 'Fecha',
        width: 150,
        renderCell: (params) => {
            return moment(params.value).format('L LTS')
        }
    },
    {
        field: 'contact',
        headerName: 'Contacto',
        description: 'Contacto',
        width: 150,
        renderCell: (params) => {
            return [params?.value?.firstName, params?.value?.lastName].filter(Boolean).join(' ')
        }
    },
    {
        field: 'agent',
        headerName: 'Vendedor',
        description: 'Vendedor',
        width: 150,
        renderCell: (params) => {
            return [params?.value?.name, params?.value?.lastname].filter(Boolean).join(' ')
        }
    },
    {
        field: 'lastTag',
        headerName: 'Etiqueta anterior',
        description: 'Etiqueta anterior',
        width: 150,
        renderCell: (params) => {
            const lastTag = params?.row?.content?.from;
            if (!lastTag) return null;

            const tag = getStatusTagByCode(lastTag);
            return tag?.name || ''
        }
    },
    {
        field: 'content',
        headerName: 'Etiqueta nueva',
        description: 'Etiqueta nueva',
        width: 150,
        renderCell: (params) => {
            const lastTag = params?.value?.to;
            if (!lastTag) return null;

            const tag = getStatusTagByCode(lastTag);
            return tag?.name || ''
        }
    },
]