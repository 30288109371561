import _ from "lodash";
import {
  isAuth
} from "../../actions/auth";
import StatusCard from "../StatusCard";
import Trophy from "../goal/Trophy";


export default function UserInformation({ panel, selectedUser, selectedGroup, rol, user }) {

  const dataOfStatusTag = panel.find(p => p.template == 'performanceGroup')
  const dataPerformance = panel.find(p => p.template === 'performance')

  const validateForShow = (obj) => {
    if (
      selectedUser
        ? obj.role.indexOf("user") < 0
        : selectedGroup
          ? obj.role.indexOf("super") < 0
          : rol && obj.role.indexOf(rol as string) < 0
    )
      return true

    return false
  }
  
  return <div className="w-full sm:sticky relative z-10  lg:top-0 md:top-[50px]">
    {
      dataOfStatusTag && user && (() => {
        if (validateForShow(dataOfStatusTag)) return

        let user_usando_list = selectedUser
          ? selectedUser._id
          : rol == "super" || rol == "comercial"
            ? undefined
            : user._id;
        let usuarios_a_mostrarlist = selectedGroup
          ? selectedGroup.users.map((u) => u._id)
          : user_usando_list;

        return (
          <>
            <StatusCard
              user={usuarios_a_mostrarlist}
              groupFilter={
                selectedGroup
                  ? selectedGroup._id
                  : undefined
              }
            />
          </>
        );
      })()
    }
    {
      dataPerformance && user && (() => {
        if (validateForShow(dataPerformance)) return

        let user_usando_per = selectedUser
          ? selectedUser._id
          : rol == "super" || rol == "comercial"
            ? undefined
            : user._id;
        let usuarios_a_mostrar_per = selectedGroup
          ? selectedGroup.users.map((u) => u._id)
          : user_usando_per;

        return <div className="flex flex-col">
          <div className="mb-2">
            {
              typeof usuarios_a_mostrar_per != 'object' && (user_usando_per || _.get(isAuth(), 'roles', []).indexOf('comercial') < 0) &&
              <Trophy
                currentUser={user}
                userSelected={selectedUser}
                user={usuarios_a_mostrar_per}
              />
            }
          </div>
        </div>
      })()
    }
  </div>
}
