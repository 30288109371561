'use client';
import { lazy, Suspense, useEffect, useRef, useState } from "react";
import { SkeletonGraph } from "./MemoizeGraph";
const MemoizeGraph = lazy(() => import('./MemoizeGraph'));

export interface GraphInterface {
  source: string,
  group: 'date' | 'week' | 'monthyear' | 'year' | 'text' | 'default' | 'user' | 'group' | 'total',
  groupby: string,
  colorby: string,
  sumtype: 'sum' | 'count' | 'count_unique' | 'default',
  sumfield: string,
  userAs?: 'email' | 'id',
  labels?: { key: string | boolean, name: string, data?: any[] }[]
  optionallabels?: { key: string | boolean, name: string, data?: any[] }[]
  testing?: boolean,
  title: string,
  id?: string,
  datefilter?: { gt?: string, lt?: string }
  userfilter?: string[],
  fillextralabels?: boolean,
  ignoreunusedlabels?: boolean,
  colorbyexist?: boolean,
  colors?: string[],
  strictLabels?: boolean,
  userkey?: string,
  percentage?: boolean,
  updateVersion?: number,
  activeUsers?: any,
  nulltext?: string,
  match?: any,
  groupsUse?: any[],
  groupTitle?: string,
  valueOption?: boolean,
  mapValues?: (name: (string | number)) => string | number
}

export default function Graph(config: GraphInterface) {
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);

  const observer = useRef<IntersectionObserver | null>(null);
  const divContainerRef = useRef<HTMLDivElement | null>(null);

  const handleObserver = (entries: IntersectionObserverEntry[]) => {
    const target = entries[0];
    if (target.isIntersecting) setIsIntersecting(true)
  }

  useEffect(() => {
    setIsIntersecting(false);
  }, [config])

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(handleObserver);
    if (divContainerRef.current) observer.current.observe(divContainerRef.current);
    return () => observer.current?.disconnect();
  }, [handleObserver]);

  return (
    <div ref={divContainerRef}>
      <Suspense fallback={<SkeletonGraph />}>
        <MemoizeGraph {...config} isIntersecting={isIntersecting} />
      </Suspense>
    </div>
  )
}