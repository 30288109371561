import fetch from 'isomorphic-fetch'
import {getCookie} from './auth'
var API = process.env.API


export const getGraphData = (data, source) => {
    let dataSend = {...data}
    if(['receipts', 'receiptsventiacontact', 'receiptscontact'].includes(dataSend.source)){
        if(dataSend.valueOption){
            dataSend.sumtype = 'sum'
            dataSend.sumfield = 'value'
        }else{
            dataSend.sumtype = 'count'
            dataSend.sumfield = '_id'
        }
    }

    return fetch(`${API}/graph/${source}?test=true`,{
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        },
        body: JSON.stringify(dataSend)
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}


export const getActiveUsers = () => {
    return fetch(`${API}/graph/activeusers`,{
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization':'JWT '+getCookie('token')
        },
    })
    .then(response => {
        return response.json()
    })
    .catch(err => console.log(err))
}