import { CardTitle } from "@/components/ui/card";
import { AlertCircle, CalendarDays, Check, CheckCircle, Clock11, Clock9, ExternalLink } from "lucide-react";
import moment from "moment";
import { useMemo } from "react";
import { defaultvaluesInfoGoals } from ".";
import { BadgeGoal } from "../ui/badgeGoal";

export default function StatusCardInformation({ goalInformation }: { goalInformation: typeof defaultvaluesInfoGoals }) {
    const { color, complete, days, lates, upToDate, veryLates } = goalInformation
    const currentDay = useMemo(() => moment().format('dddd'), [goalInformation])

    return <>
        <div className="flex flex-col gap-1 w-full">
            <div className="flex items-start justify-between">
                <div className="flex-col 2xl:flex-row flex">
                    <CardTitle className="text-[14px] md:text-[16px] space-x-2 flex items-center">
                        <span>
                            {lates > 0 || veryLates
                                ? <AlertCircle size={19} className="text-red-500" />
                                : <CheckCircle size={19} className="text-green-500" />}
                        </span>
                        <span>
                            {lates > 0 && veryLates === 0 && (
                                <>
                                    {lates} vendedores están atrasados en el cierre hoy <span className="capitalize">{currentDay}</span>
                                </>
                            )}
                            {veryLates > 0 && (
                                <>
                                    {veryLates} vendedores están muy atrasados en el cierre de hoy <span className="capitalize">{currentDay}</span>
                                </>
                            )}
                            {veryLates === 0 && lates === 0 && (
                                <>
                                    Tus vendedores están al día en el cierre de hoy <span className="capitalize">{currentDay}</span>
                                </>
                            )}
                        </span>
                    </CardTitle>
                    <div className="flex">
                    </div>
                    <div className="flex items-center shrink-0">
                        <div className="flex flex-row items-center flex-wrap">
                            {veryLates > 0 && <BadgeGoal className="border-0 text-[12px]" variant={'destructive'}>
                                <Clock11 size={13} />
                                Muy atrasados {veryLates}
                            </BadgeGoal>}
                            {lates > 0 && <BadgeGoal className="border-0 text-[12px]" variant={'lates'}>
                                <Clock9 size={13} />
                                Atrasados {lates}
                            </BadgeGoal>}
                            {upToDate > 0 && <BadgeGoal className="border-0 text-[12px]" variant={'success'}>
                                <Check size={13} />
                                Al día {upToDate}
                            </BadgeGoal>}
                            {days !== '' && <BadgeGoal className="border-0 text-[12px]">
                                <CalendarDays size={13} />
                                {days} días
                            </BadgeGoal>}
                        </div>
                    </div>
                </div>
                <div className="flex text-slate-800 lg:gap-1">
                    <p className="font-medium text-[14px] hidden lg:block shrink-0">Ver rendimiento</p>
                    <ExternalLink className="shrink-0" size={18} />
                </div>
            </div>
        </div>
    </>
}
