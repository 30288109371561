import react, { useEffect } from 'react'
import { isAuth } from '../actions/auth'
import Router from 'next/router'
import { getVarUrl } from '../actions/auth'
import Module from './core/Module'

const Private = ({ title, children, user }) => {

    return (
        <Module >
            <h2 className="ml-3 mb-3">{getVarUrl(title, user)}</h2>
            <p dangerouslySetInnerHTML={{ __html: getVarUrl(children, user) }}></p>
        </Module>
    )


}

export default Private

