import { getMeetingsList } from '@/actions/crm/calendar'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

export default function useGetMeetingsList({ userSelected = 'all', group = undefined }) {
    const [meetings, setMeetings] = useState<any[]>([])
    const [paramns, setParams] = useState({
        page: 1,
        limit: 500,
        'gtcontent.endMeeting': new Date().toISOString(),
        'ltcontent.endMeeting': dayjs().endOf('day').toISOString()
    })
    
    const { data, ...rest } = useQuery({
        queryKey: ['meetings-list-now', paramns, userSelected, group],
        queryFn: () => getMeetingsList({ ...paramns, agent: userSelected ?? 'all', group })
    })

    useEffect(() => {
        const meetingsList = data?.meetings || []
        setMeetings(meetingsList)
    }, [data])

    const onChangeMeetingList = (list) => setMeetings(list)

    return {
        onChangeMeetingList,
        paramns,
        meetings,
        ...rest
    }
}
