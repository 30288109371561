import { Skeleton } from "@/components/ui/skeleton";

export default function LoadingTabsList() {
    return (
        <div className="flex items-center gap-[1px]">
            {
                new Array(4).fill(null).map((_, inx) => <Skeleton className={`w-[60px] rounded-none h-[30px] ${inx === 0 ? 'rounded-l' : inx == 3 ? 'rounded-r' : ''}`} />)
            }
        </div>
    )
}