import { getCompany } from '@/actions/auth'
import GraphPanel from '@/components/graph/GraphPanel'
import moment from 'moment'
import { useMemo } from 'react'
import { chartsClosures, chartsManagement } from '../../constants/chartsTypes'

export default function Closures({ user }) {
    const company = useMemo(() => getCompany(), []);

    const graphs = useMemo(() => {
        return [chartsManagement({ company })['closures'], ...Object.values(chartsClosures({ company }))]
            .flat()
            .filter((chart: any) => !chart.hasOwnProperty('active') || chart?.active)
    }, [company])

    return (
        <GraphPanel
            title='Cierres'
            useAsUser={user}
            defaultGroupType={"monthyear"}
            initialDateFilter={"last_3_month"}
            graphs={graphs as any}
            filters={[
                {
                    title: "Cierre",
                    key: 'to',
                    keysModel: {
                        closescontact: 'to'
                    },
                    options: [
                        ...company.archivingReasons.map((o) => [o.code, o.name])
                    ]
                },
                {
                    title: "Origen",
                    key: 'closescontact',
                    keysModel: {
                        closescontact: 'closescontact'
                    },
                    options: [
                        ...company.origins.map((o) => [o.code, o.name])
                    ]
                },
                {
                    title: 'Atención',
                    key: 'atention',
                    keysModel: {
                        closescontact: 'prospect.stats.attention'
                    },
                    options: [
                        ['not_actions', 'Sin acciónes'],
                        ['chat', 'Chat'],
                        ['call', 'Llamada'],
                        ['call_chat', 'Llamada + Chat'],
                        ['tracing', 'Seguimiento'],
                        ['tracing_chat', 'Seguimiento + Chat'],
                    ]
                }
            ]}
            initialDateValue={
                {
                    gt: moment().add(-2, 'month').startOf('month').startOf('day').add(-1, 'd').toISOString(),
                }
            }

        />
    )
}
