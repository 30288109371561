
import { Skeleton } from "@/components/ui/skeleton";

export function LoaderComissionModule() {
    return (
        <ul className="grid gap-4 mt-4">
            <ComissionItemSkeleton />
            <ComissionItemSkeleton countComissions={3} />
            <ComissionItemSkeleton countComissions={2} />
            <ComissionItemSkeleton countComissions={1} />
        </ul>
    )
}

export function ComissionItemSkeleton({ countComissions = 4 }: { countComissions?: number }) {
    return (
        <div className="w-full grid gap-1">
            <Skeleton className="mb-1 h-3 rounded-md w-28" />
            <ul className="grid gap-2 px-1">
                {
                    Array.from({ length: countComissions }).fill(null).map((__, inx) => (
                        <li className="flex items-center w-full justify-between" key={inx}>
                            <div className="flex items-center flex-grow overflow-hidden gap-2">
                                <Skeleton className="h-2 w-20 rounded-md" />
                                <Skeleton className="h-2 w-14 rounded-md" />
                            </div>
                            <Skeleton className="h-2 w-10 rounded-md" />
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}