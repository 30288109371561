import { getLastGroupFast } from '@/actions/call'
import React, { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

export default function useGetCalls({ user = null }) {
    const { data, isLoading, isError } = useQuery({
        queryFn: getLastGroupFast,
        queryKey: ['all-called-sellers', user]
    })
    
    const allCalls = useMemo(() => data ?? [], [data])

    return { isLoadingFetchCall: isLoading, calls: allCalls, isErrorFetchCall: isError }
}
