import { ArrowForward, ArrowForwardIos, ArrowRight, ArrowRightAlt, AttachMoney, Call, Celebration, CheckCircleOutline, EmojiEvents, Expand, ExpandLess, ExpandMore, Group, Phone, PointOfSale, RecordVoiceOver, Scale, SpatialTracking } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, ButtonBase, Card, CardHeader, Chip, Collapse, Divider, Grid, IconButton, LinearProgress, Paper, Tooltip, Typography } from "@mui/material"
import _ from "lodash"
import { useMemo, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { isAuth } from "../../../actions/auth"
import { getDailyAlerts, getDailyStats } from "../../../actions/comercial/metas"
import { list, listAll, listUser } from "../../../actions/instance"
import getColorToCodeMui from "../../../helpers/getColorToCodeMUI"
import Loading from "../../core/Loading"
import Module from "../../core/Module"
import InstanceCard from "../../evaluate/InstanceCard"
import AlertAllModal from "./AlertAllModal"
import AlertCard from "./AlertCard"
import { AlertMap } from "./AlertMap"


interface AlertReportInterface {
    user: any,
    clickedAvisos?: () => void
}


const AlertUserReport = ({user, clickedAvisos}:AlertReportInterface) => {
    const [show, setShow] = useState(false)
    const [showAll, setShowAll] = useState(false)
    let userToUse = user
    if(!user){
        userToUse = _.get(isAuth(), '_id', '') as string
    }

    let statsQuery = useQuery({
        queryFn: () => getDailyAlerts(userToUse),
        queryKey: ['user-alerts-query',userToUse]
    })
    
    let data = _.get(statsQuery, 'data.data' ,{})

    const expandAlerts = () => {
        setShow(!show)
    }

    
    const relevantAlertList = useMemo(() => {
        return _.get(statsQuery, 'data.data.alerts', []).sort((a,b) => a.level - b.level)
    },[statsQuery.data])


    if(statsQuery.isFetching || statsQuery.error ||  statsQuery.data.data.error) return <Module sx={{pb:0, height: '100%'}}>
        <Loading />
    </Module>

    return <Module 
            titleElement={<span onClick={clickedAvisos}>Alertas</span>}
            sx={{ height: "100%" }}
            sxCardContent={{p:0}}
            >
                <Grid item container direction={{md: 'column'}} pb={0} spacing={0} rowSpacing={0.5} xs>
                        {relevantAlertList.slice(0,5).map((alert) => <AlertCard alert={alert} />)}
                        <Collapse in={showAll}>
                            {relevantAlertList.slice(5,relevantAlertList.length).map((alert) => <AlertCard alert={alert} />)}
                        </Collapse>
                        <Button onClick={() => setShowAll(!showAll)}>{showAll? 'Ver menos' : (relevantAlertList.length - 5 <= 0 ? 'Ver todos' : `Ver ${relevantAlertList.length - 5} más`)} </Button>
                </Grid>
            </Module>
}


export default AlertUserReport