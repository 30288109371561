import { setGraphSelectedContact } from "@/redux/slices/graph";
import { Chat } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import moment from "moment";
import { useDispatch } from "react-redux";


export default [
    {
        field: "contact_id",
        headerName: "Chat",
        description: "Abrir chat",
        width: 50,
        renderCell: (params) => {
            const dispatch = useDispatch()
            return <IconButton onClick={() => dispatch(setGraphSelectedContact(params.value))} disabled={!params.value || params.value == ""}>
                <Chat fontSize="small" sx={{ color: "#aaa" }} />
            </IconButton>
        }
    },
    {
        field: "date",
        headerName: "Fecha Ingreso",
        description: "Fecha Ingreso",
        width: 150,
        renderCell: (params) => {
            return moment(new Date(params.value)).format('L')
        }
    },
    {
        field: "date_sale",
        headerName: "Fecha Venta",
        description: "Fecha Venta",
        width: 150,
        renderCell: (params) => {
            return params.value ? moment(new Date(params.value)).format('L') : ''
        }
    },
    {
        field: "name",
        headerName: "Nombre",
        description: "Nombre",
        width: 200,
        renderCell: (params) => {
            return params.value;
        }
    },
    {
        field: "ticket_id",
        headerName: "ID",
        description: "ID",
        width: 130,
        renderCell: (params) => {
            return params.value;
        }
    },
    {
        field: "ticket_sale_id",
        headerName: "ID Venta",
        description: "ID Venta",
        width: 200,
        renderCell: (params) => {
            return params.value;
        }
    },

    {
        field: "status",
        headerName: "Estado",
        description: "Estado",
        width: 150,
        cellClassName: (params) => {
            if (params.row.sold == true && params.row.cancel == false) {
                return 'vendido';
            }
            if (params.row.sold == false && params.row.cancel == true) {
                return 'novendido'
            }
            if (params.row.sold == false && params.row.cancel == false) {
                return 'default'
            }
        }
    },

    {
        field: "user",
        headerName: "Vendedor",
        description: "Vendedor",
        width: 150,
        sortable: false,
        renderCell: (params) => {
            return params.value ? params.value.name + ' ' + params.value.lastname : '';
        }
    },
    {
        field: "phone",
        headerName: "Teléfono",
        description: "Teléfono",
        width: 150,
        renderCell: (params) => {
            return params.value;
        }
    },
    // {
    //     field: "type",
    //     headerName: "Fuente",
    //     description: "Fuente",
    //     width: 200,
    //     renderCell: (params) => {
    //         return params.value;
    //     }
    // },
    {
        field: "name",
        headerName: "Nombre",
        description: "Nombre",
        width: 200,
        renderCell: (params) => {
            return params.value;
        }
    },
    {
        field: "product",
        headerName: "Producto",
        description: "Producto",
        width: 200,
        renderCell: (params) => {
            return params.value;
        }
    },
    {
        field: "type",
        headerName: "Tipo",
        description: "Tipo",
        width: 150,
        renderCell: (params) => {
            return params.value;
        }
    },
]