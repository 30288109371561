export interface IGoalInfo {
    readonly lates: number
    readonly veryLates: number
    readonly upToDate: number
    readonly complete: number
    readonly color: string
    readonly days: string
}

export interface IGoalState {
    readonly state: string;
    readonly complete: boolean;
    readonly color: any;
    readonly expected: number
}

export interface IGoalStateKey extends IGoalState {
    key: string
    expected: number
}

export enum STATES_GOAL {
    BAD = 'bad',
    VERYBAD = 'veryBad',
    UPTODATE = 'upToDate',
    COMPLETE = 'complete'
}

export const fieldColors = {
    [STATES_GOAL.VERYBAD]: { color: '#d32f2f', counter: 'veryLates' },
    [STATES_GOAL.BAD]: { color: '#ED6C02', counter: 'lates' },
    [STATES_GOAL.UPTODATE]: { color: '#2e7d32', counter: 'upToDate' },
    NOT_GOAL: { color: '#0288D1' }
};

export enum STATUS_COLORS {
    VERYBAD = '#d32f2f',
    BAD = '#ED6C02',
    UPTODATE = '#2e7d32'
}

export const colorsFilter = [
    { color: fieldColors[STATES_GOAL.VERYBAD].color, label: 'Rojo' },
    { color: fieldColors[STATES_GOAL.BAD].color, label: 'Naranja' },
    { color: fieldColors[STATES_GOAL.UPTODATE].color, label: 'Verde' }
]