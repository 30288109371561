import { Skeleton } from "@/components/ui/skeleton";

export default function LoaderCharts() {
    return (
        <div className="gap-5 grid md:grid-cols-2 grid-cols-1 w-full">
            <Skeleton className="w-full h-[340px]" />
            <Skeleton className="w-full h-[340px]" />
            <Skeleton className="w-full h-[340px]" />
            <Skeleton className="w-full h-[340px]" />
        </div>
    )
}