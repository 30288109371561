import { useMemo } from 'react'
import { DefaultTabs } from '../../constants/charts'
import TabsContent from '../tabs-content'
import Clients from './clients'
import Closures from './closures'
import Management from './management'
import Personalize from './personalize'
import Sales from './sales'

export default function ChartsByTypeSelected({
    activeTab,
    user,
    dashboardCharts = []
}) {

    const chartsOptions = useMemo(() => {
        const defaultCharts = [
            {
                element: <Management user={user} />,
                value: DefaultTabs.Management
            },
            {
                element: <Sales user={user} />,
                value: DefaultTabs.Sales
            },
            {
                element: <Clients user={user} />,
                value: DefaultTabs.Clients
            },
            {
                element: <Closures user={user} />,
                value: DefaultTabs.Closures
            }]

        const customCharts = dashboardCharts.map((dashboardChart: any) => ({
            value: dashboardChart._id,
            element: <Personalize user={user} dashboardChart={dashboardChart} />
        }))

        return [...defaultCharts, ...customCharts]
    }, [user, dashboardCharts])

    return (
        <>
            {
                chartsOptions.map(({ element, value }) => (
                    <TabsContent activeTab={activeTab} value={value} key={value}>
                        {element}
                    </TabsContent>
                ))
            }
        </>
    )
}
