import { setGraphSelectedContact } from "@/redux/slices/graph";
import { Chat } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import moment from "moment";
import { useDispatch } from "react-redux";

export default [
    {
        field: "ventiaContactId",
        headerName: "Chat",
        description: "Abrir chat",
        width: 50,
        renderCell: (params) => {
            const dispatch = useDispatch()
            return <IconButton onClick={() => dispatch(setGraphSelectedContact(params.value))} disabled={!params.value || params.value == ""}>
                <Chat fontSize="small" sx={{ color: "#aaa" }} />
            </IconButton>
        }
    },
    {
        field: "createdAt",
        headerName: "Fecha y hora creado",
        description: "Creado",
        width: 200,
        renderCell: (params) => {
            return moment(new Date((params.value))).format('L LTS')

        }
    },
    {
        field: "agent",
        headerName: "Dueño actual",
        description: "Nombre del vendedor que actualmente poseer el contacto",
        width: 200,
        renderCell: (params) => {
            return (params.value && params.value.firstName) ? `${params.value.firstName} ${params.value.lastName}` : ''
        }
    },

    {
        field: "firstAgent",
        headerName: "Creador del contacto",
        description: "Nombre del vendedor primer vendedor en crear o tomar el contacto",
        width: 200,
        renderCell: (params) => {
            return (params.value && params.value.firstName) ? `${params.value.firstName} ${params.value.lastName}` : ''
        }
    },
    {
        field: "phones",
        headerName: "Teléfono contacto",
        description: "Teléfono del agente",
        width: 200,
        renderCell: (params) => {
            if (params.value && params.value[0]) {
                return `${params.value[0]}`
            } else {
                return '';
            }
        }
    },
    {
        field: "firstName",
        headerName: "Nombre contacto",
        description: "Nombre del contacto",
        width: 200,
        renderCell: (params) => {
            return params.value ? params.value : '';
        }
    },
    {
        field: "lastName",
        headerName: "Apellido contacto",
        description: "Apellido del contacto",
        width: 200,
        renderCell: (params) => {
            return params.value ? params.value : '';

        }
    },
    {
        field: "leads",
        headerName: "Origen",
        description: "Origen del prospecto",
        width: 200,
        renderCell: (params) => {
            let origenes = '';
            for (let i = 0; i < params.value.length; i++) {
                if (i == params.value.length - 1) {
                    origenes += params.value[i].source;
                } else {
                    origenes += params.value[i].source + ", ";
                }
            }
            return origenes;
        }
    },
    {
        field: "stats",
        headerName: "Atención",
        description: "Origen del prospecto",
        width: 200,
        renderCell: (params) => {
            switch (params.value ? params.value.attention : 'not_register') {
                case 'chat':
                    return 'Chat'
                    break;
                case 'not_actions':
                    return 'Sin acciónes'
                    break;
                case 'call':
                    return 'Llamada'
                    break;
                case 'call_chat':
                    return 'Llamada + Chat'
                case 'tracing':
                    return 'Seguimiento';
                case 'tracing_chat':
                    return 'Seguimiento + Chat'
                default:
                    return 'Sin registro'
            }
        }
    },
]